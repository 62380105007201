import { useDispatch, useSelector } from "react-redux"
import { selectLanguage } from "../../redux/slice/language/laguage.slice"
import { useEffect } from "react"
import { updateTab } from "../../redux/slice/ui/ui-slice"
import styles from "../../styles/page-styles/legal-notice/legal-notice.module.scss"
import { constants } from "../../constants"
export function LegalNotice() {
    const language = useSelector(selectLanguage)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(updateTab({ tab: 'Legal Notice' }))
    }, [])
    return (
        <div className={styles.legalNotice}>
            <div className={styles.responsibility}>
                <p>{constants[language].responsible_for}:</p>
                <div className={styles.ownerInfo}>
                    <span>Srecko Boban</span>
                    <address>Breitscheidstrasse 93A</address>
                    <address>70176 Stuttgart</address>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.section}>
                    <h1 className={styles.heading}>{constants[language].desclaimer}</h1>
                    <p className={styles.description}>{constants[language].desclaimer_desc}</p>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>{constants[language].privacy_policy}</h1>
                    <p className={styles.description}>{constants[language].privacy_policy_desc}</p>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>1. {constants[language].usage_data}</h1>
                    <p className={styles.description}>{constants[language].usage_data_descriptions}</p>
                    <p className={styles.description}>{constants[language].usage_data_bullets_heading}:</p>
                    <ul className={styles.bullets}>
                        {constants[language].usage_data_bullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>2. {constants[language].personal_data}</h1>
                    <p className={styles.description}>{constants[language].personal_data_desc}</p>
                    <p className={styles.description}>{constants[language].specifically}:</p>
                    <ol className={styles.bullets}>
                        {constants[language].personal_data_bullets.map((bullet, index) => <li key={index}>
                            <div className={styles.bullet}>
                                <h2 className={styles.subHeading}>{bullet.heading}</h2>
                                {bullet.descriptions.map((description, index) => <p key={index} className={styles.description}>{description}</p>)}
                            </div>
                        </li>)}
                    </ol>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>3. {constants[language].cookies}</h1>
                    {constants[language].cookies_descriptions.map((description, index) => <p key={index} className={styles.description}>{description}</p>)}
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>4. {constants[language].usage_social_media}</h1>
                    {constants[language].usage_social_media_descriptions.map((description, index) => <p key={index} className={styles.description}>{description}</p>)}
                    <p className={styles.description}>{constants[language].usage_social_media_bullets_heading}:</p>
                    <ol className={styles.bullets}>
                        {constants[language].usage_social_media_bullets.map((bullet, index) => <li key={index}>
                            <div className={styles.bullet}>
                                <h2 className={styles.subHeading}>{bullet.heading}</h2>
                                {bullet.descriptions.map((description, index) => <p key={index} className={styles.description}>{description}</p>)}
                            </div>
                        </li>)}
                    </ol>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>5. {constants[language].use_pulgin_youtube}</h1>
                    {constants[language].use_pulgin_youtube_descriptions.map((description, index) => <p key={index} className={styles.description}>{description}</p>)}
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>6. {constants[language].usage_google_utility}</h1>
                    <ol className={styles.bullets}>
                        {constants[language].usage_google_utility_bullets.map((bullet, index) => <li key={index}>
                            <div className={styles.bullet}>
                                <h2 className={styles.subHeading}>{bullet.heading}</h2>
                                {bullet.descriptions.map((description, index) => <p key={index} className={styles.description}>{description}</p>)}
                            </div>
                        </li>)}
                    </ol>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>7. {constants[language].duration_storage}</h1>
                    <p className={styles.description}>{constants[language].duration_storage_desc}</p>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>8. {constants[language].rights_data_subject}</h1>
                    <ol className={styles.bullets}>
                        {constants[language].rights_data_subject_bullets.map((bullet, index) => <li key={index}>
                            <div className={styles.bullet}>
                                <h2 className={styles.subHeading}>{bullet.heading}</h2>
                                <p className={styles.description}>{bullet.description}</p>
                                <p className={styles.description}>{bullet.footer}</p>
                            </div>
                        </li>)}
                    </ol>
                </div>
                <div className={styles.section}>
                    <h1 className={styles.heading}>9. {constants[language].security}</h1>
                    <p className={styles.description}>{constants[language].security_desc}</p>
                </div>
            </div>
        </div>
    )

}