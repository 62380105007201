import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAd } from '../../../components/admin/modals/create-ad';
import { ViewAd } from '../../../components/admin/modals/view-ad';
import { PrimaryButton } from '../../../components/general/input/primary-button';
import { AdsTable } from '../../../components/general/table/ads-table';
import { createAdAsync, getAdAsync, getAdsAsync, removeAd, selectAds } from '../../../redux/slice/ad/ad-slice';
import { updateTab } from '../../../redux/slice/ui/ui-slice';
import styles from "../../../styles/page-styles/admin/ads.module.scss";
import { constants } from '../../../constants';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
export function Ads() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateTab({ tab: 'Ads' }))
    }, [])
    const [openCreateAd, setOpenCreateAd] = React.useState(false);
    const [openViewAd, setOpenViewAd] = React.useState(false);
    const ads = useSelector(selectAds)
    const langauge = useSelector(selectLanguage)
    useEffect(() => {
        if (!ads || ads.length === 0) {
            dispatch(getAdsAsync({}))
        }
    })
    const handleCreateAd = () => {
        setOpenCreateAd(true);
    }
    const onView = (id) => {
        dispatch(getAdAsync({ adId: id }));
        setOpenViewAd(true);
    }
    const onRemove = (id) => {
        dispatch(removeAd({ adId: id })).then(() => dispatch(getAdsAsync({})))
    }
    const onPublish = () => {
        dispatch(getAdsAsync({}))
        setOpenCreateAd(false);
        // setOpenViewAd(true);

    }
    return <div className={styles.ads}>
        <CreateAd isOpen={openCreateAd} onClose={() => setOpenCreateAd(false)}
            onPublish={onPublish}
        />
        <ViewAd isOpen={openViewAd} onClose={() => setOpenViewAd(false)} />
        <div className={styles.btn}>
            <PrimaryButton style={{ width: "168px" }}
                onClick={handleCreateAd}
            >{constants[langauge].create_ad}</PrimaryButton>
        </div>
        <div className={styles.mobileBtn}>
            <PrimaryButton style={{ width: "168px", height: "40px", fontSize: "12px" }}
                onClick={handleCreateAd}
            >{constants[langauge].create_ad}</PrimaryButton>
        </div>
        <div className={styles.table}>
        <AdsTable ads={ads} onView={onView} 
        onRemove={onRemove}
        />
        </div>
    </div>
}