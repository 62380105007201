import { useEffect, useRef, useState } from "react";
import add_red_ic from "../../../assets/icons/add-music-red.svg";
import add_ic from "../../../assets/icons/add-music.svg";
import music_red_ic from "../../../assets/icons/music-red.svg";
import music_ic from "../../../assets/icons/music.svg";
import remove_ic from "../../../assets/icons/remove-music.svg";
import styles from "../../../styles/component-styles/general/table.module.scss";
import no_data from "../../../assets/images/no_data.svg"
import loader from "../../../assets/gifs/loader.webp"
import { useDispatch } from "react-redux";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { useSelector } from "react-redux";
import { constants } from "../../../constants";
import { addSongToPlaylist, removeSongFromPlaylist } from "../../../redux/slice/playlist/playlist-slice";
import { useInView } from "react-intersection-observer";
export function Table({ songs, isPlaylist = false, onRemove = () => { }, onAdd = () => { }, isSortable = false, onSort = () => { }, sortBy, setCurrentPage, currentPage, totalPages }) {
    const [active, setActive] = useState(null)
    const dropdownRef = useRef(null)
    const [action, setAction] = useState(null)
    const language = useSelector(selectLanguage)
    const dispatch = useDispatch()
    const [ref, inView] = useInView({ triggerOnce: false })

    useEffect(() => {
        if (inView) {
            if (currentPage <= totalPages)
                setCurrentPage(currentPage + 1)
        }
    }, [inView])
    const addToPlaylist = (id) => {
        dropdownRef.current.style.display = 'none';
        dispatch(addSongToPlaylist({ songId: id })).then(onAdd)
    }
    const removeFromPlaylist = (id) => {
        dropdownRef.current.style.display = 'none';
        dispatch(removeSongFromPlaylist({ songId: id })).then(onRemove)
    }
    return <table className={styles.table}>
        <thead>
            <tr>
                <th className={styles.head}>
                    {constants[language].no}</th>
                <th className={styles.head}>
                    <div className={styles.header}>
                        {constants[language].title}
                        {isSortable && <div className={styles.sorting}>
                            <button className={styles.sortingBtn + " " + (sortBy == 'titleAsc' ? styles.active : "")}
                                onClick={() => onSort('titleAsc')}
                            >
                                ▲
                            </button>
                            <button className={styles.sortingBtn + " " + (sortBy == 'titleDesc' ? styles.active : "")}
                                onClick={() => onSort('titleDesc')}>
                                ▼
                            </button>
                        </div>}
                    </div>
                </th>
                <th className={styles.head}>
                    <div className={styles.header}>
                        {constants[language].artist}
                        {isSortable && <div className={styles.sorting}>
                            <button className={styles.sortingBtn + " " + (sortBy == 'artistAsc' ? styles.active : "")}
                                onClick={() => onSort('artistAsc')}>
                                ▲
                            </button>
                            <button className={styles.sortingBtn + " " + (sortBy == 'artistDesc' ? styles.active : "")}
                                onClick={() => onSort('artistDesc')}>
                                ▼
                            </button>
                        </div>}
                    </div>
                </th>
                <th className={styles.head}>
                    <div className={styles.header}>
                        {constants[language].language}
                        {isSortable && <div className={styles.sorting}>
                            <button className={styles.sortingBtn + " " + (sortBy == 'genreAsc' ? styles.active : "")}
                                onClick={() => onSort('genreAsc')}>
                                ▲
                            </button>
                            <button className={styles.sortingBtn + " " + (sortBy == 'genreDesc' ? styles.active : "")}
                                onClick={() => onSort('genreDesc')}>
                                ▼
                            </button>
                        </div>}
                    </div>
                </th>
                <th className={styles.head}>{constants[language].action}</th>

            </tr>
        </thead>

        <tbody>
            <div className={`${styles.dropdown}`} ref={dropdownRef}>
                <div className={styles.action}
                >{action}</div>
            </div>
            <tr style={{ height: "fit-content" }}>
                <td colSpan={5}>
                    <div className={styles.line}></div>
                </td>

            </tr>

            {songs && songs.map((song, index) => <tr key={index}
                ref={index === songs.length - 1 ? ref : null}
            // onClick={() => setActive(index)} className={active === index ? styles.active : ""}
            >
                <td><div className={`${styles.no}  ${active == index ? styles.active : ""}`}>{ index + 1}</div></td>
                <td><div className={`${styles.title} ${active == index ? styles.active : ""}`}><img src={active == index ? music_red_ic : music_ic} /><span>{song.title}</span></div></td>
                <td><div className={`${styles.artist}  ${active == index ? styles.active : ""}`}>{song.artist}</div></td>
                <td><div className={`${styles.genre}  ${active == index ? styles.active : ""}`}>{song.genre}</div></td>
                <td><div className={styles.action}
                >
                    {isPlaylist ? <img src={remove_ic}
                        onClick={() => removeFromPlaylist(song._id)}
                        onMouseEnter={(e) => {
                            dropdownRef.current.style.display = "flex";
                            dropdownRef.current.style.top = `${index * 50}px`;
                            setAction(constants[language].remove_from_playlist)
                        }}
                        onMouseLeave={() => {
                            dropdownRef.current.style.display = "none";
                        }}
                    /> : <img src={active == index ? add_red_ic : add_ic}
                        onClick={() => addToPlaylist(song._id)}
                        onMouseEnter={() => {
                            dropdownRef.current.style.display = "flex";
                            dropdownRef.current.style.top = `${index * 50}px`;
                            setAction(constants[language].add_to_playlist)
                        }}
                        onMouseLeave={() => {
                            dropdownRef.current.style.display = "none";
                        }}
                    />}

                </div></td>

            </tr>)}
            {songs && songs?.length == 0 && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={no_data} />
                    <p>{constants[language].no_data}</p>
                </div>
            </td></tr>}
            {!songs && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={loader} />
                    <p>{constants[language].loading}</p>
                </div>
            </td></tr>}
        </tbody>
    </table>
}