import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
export const setLanguageAsync = createAsyncThunk(
    'language/set',
    async ({ language }, thunkApi) => {
        sessionStorage.setItem('language', language);
        thunkApi.dispatch(setLanguage(language));
        return language;
    }
)
const languageSlice = createSlice({
    name: "language",
    initialState: {
        loading: false,
        error: null,
        language: sessionStorage.getItem('language') || 'en',
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
            toast.success("Site language changed successfully!");
        }
    }
})
export const { setLanguage } = languageSlice.actions;
export const selectLanguage = state => state.language.language;
export default languageSlice.reducer;