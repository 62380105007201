import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiPOST_File, apiGET, apiPOST, apiPOST_File_Progresss } from "../../../apis/service";
import { toast } from "react-toastify";
import { updateIsLoaderOpen } from "../ui/ui-slice";

export const uploadVideoAsync = createAsyncThunk("video/uploadVideoAsync", async ({ files, titles }, thunkAPI) => {
  try {
    thunkAPI.dispatch(updateIsLoaderOpen({ isLoaderOpen: true }));

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("videos", file);
      formData.append("titles", titles[index]);
    });

    const response = await apiPOST_File_Progresss("upload-mutliple-videos", formData, (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      thunkAPI.dispatch(setUploadProgress({ index: 0, progress })); // Update progress for all uploads
    });

    thunkAPI.dispatch(getTopVideosAsync({ no: 6 }));
    thunkAPI.dispatch(updateIsLoaderOpen({ isLoaderOpen: false }));
    thunkAPI.dispatch(setUploadProgress({ index: 0, progress: 100 }));

    if (response.success) {
      toast.success(`Uploaded ${response.uploadedCount} videos`);
    } else {
      toast.error("Error uploading videos");
    }

    return response;
  } catch (error) {
    toast.error(error.message);
  }
});

const uploadSingleVideo = async (file, title, index, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append("video", file);
    formData.append("title", title);

    const response = await apiPOST_File_Progresss("upload-video", formData, (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total) - 20 || 0;
      thunkAPI.dispatch(setUploadProgress({ index, progress }));
    });

    if (response.success) {
      toast.success(`Uploaded ${index}`);
    } else {
      toast.error(`Error uploading ${index}`);
    }

    return response;
  } catch (e) {
    toast.error(e.message);
    return { success: false };
  }
};

export const getVideosAsync = createAsyncThunk(
  "video/get",
  async ({ query, start, limit, sortBy, append = false }, thunkAPI) => {
    try {
      if (!append) thunkAPI.dispatch(getVideosStart());
      const response = await apiGET("videos", { query, start, limit, sortBy });
      if (response.success) {
        if (append) thunkAPI.dispatch(appendResult(response));
        else thunkAPI.dispatch(getVideosSuccess(response));
      } else toast.error(response.message);
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);
export const getVideoAsync = createAsyncThunk("video/getbyId", async ({ id }, thunkAPI) => {
  try {
    thunkAPI.dispatch(getVideoStart());
    const response = await apiGET(`videos/${id}`);
    if (response.success) thunkAPI.dispatch(getVideoSuccess(response));
    else toast.error(response.message);
    return response;
  } catch (error) {
    toast.error(error.message);
  }
});
export const getTopVideosAsync = createAsyncThunk("video/top", async ({ no = 6 }, thunkAPI) => {
  try {
    const response = await apiGET("videos", { sortBy: "createdAtDesc", start: 0, limit: no });
    console.log("response---", response);
    if (response.success) thunkAPI.dispatch(getTopVideosSuccess(response));
    else toast.error(response.message);
    return response;
  } catch (error) {
    toast.error(error.message);
  }
});
export const deleteVideosAsync = createAsyncThunk("video/delete", async ({ ids, current }, thunkAPI) => {
  try {
    const response = await apiPOST("delete-videos", { ids });
    if (response.success) {
      thunkAPI.dispatch(deleteVideosSuccess(ids));
      toast.success(`${response.success} ${response.success > 1 ? "videos" : "video"} deleted successfully!`);
    } else toast.error(response.message);
    return response;
  } catch (error) {
    toast.error(error.message);
  }
});
export const onDownloadVideoAsync = createAsyncThunk("video/download", async ({ videoId }, thunkAPI) => {
  try {
    const response = await apiPOST("on-download", { videoId });
    return response;
  } catch (error) {
    toast.error(error.message);
  }
});
const videoSlice = createSlice({
  name: "video",
  initialState: {
    videos: null,
    topVideos: null,
    video: null,
    total: 0,
    uploadProgress: {},
  },
  reducers: {
    getVideosSuccess: (state, action) => {
      state.videos = action.payload.videos;
      state.total = action.payload.total;
    },
    getVideosStart: (state, action) => {
      state.videos = null;
    },
    getVideoSuccess: (state, action) => {
      state.video = action.payload.video;
    },
    appendResult: (state, action) => {
      if (!state.videos) {
        state.videos = action.payload.videos;
      } else {
        let count = 0;
        for (let i = 0; i < action.payload.videos.length; i++) {
          const video = action.payload.videos[i];
          if (state.videos.find((v) => v._id === video._id)) count = count + 1;
        }
        if (count < action.payload.videos.length) state.videos = [...state.videos, ...action.payload.videos];
      }
      state.total = action.payload.total;
    },
    getVideoStart: (state, action) => {
      state.video = null;
    },
    getTopVideosSuccess: (state, action) => {
      state.topVideos = action.payload.videos;
    },
    deleteVideosSuccess: (state, action) => {
      state.videos = state.videos?.filter((v) => !action.payload.includes(v._id));
      state.topVideos = state.topVideos?.filter((v) => !action.payload.includes(v._id));
    },
    setUploadProgress: (state, action) => {
      const { index, progress } = action.payload;
      console.log(state.uploadProgress);
      state.uploadProgress[index] = progress;
    },
    clearUploadProgress: (state) => {
      state.uploadProgress = {};
    },
  },
});

export const {
  getVideosSuccess,
  getVideoSuccess,
  getTopVideosSuccess,
  getVideosStart,
  getVideoStart,
  appendResult,
  deleteVideosSuccess,
  setUploadProgress,
  clearUploadProgress,
} = videoSlice.actions;
export const selectVideos = (state) => state.video.videos;
export const selectVideo = (state) => state.video.video;
export const selectTotal = (state) => state.video.total;
export const selectTopVideos = (state) => state.video.topVideos;
export const selectUploadProgress = (state) => state.video.uploadProgress;
export default videoSlice.reducer;
