import styles from "../../styles/component-styles/login/footer.module.scss";
import logo from "../../assets/icons/logo.svg"
export function Footer(){
    return <div className={styles.footer}>
        <hr />
        <div className={styles.footerContent}>
        <span className={styles.poweredBy}>
            <img src={logo} alt="felix" />
        </span>
        <span className={styles.copyright}>© 2024 powered by felix. All rights reserved.</span>
        </div>
        </div>
}