import style from '../../../styles/component-styles/admin/modals.module.scss';
import { Overlay } from './overlay';
import close_ic from "../../../assets/icons/close.svg";
import { PrimaryButton } from '../../general/input/primary-button';
import { useSelector } from 'react-redux';
import { selectAd } from '../../../redux/slice/ad/ad-slice';
import { constants } from '../../../constants';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
export function ViewAd({ isOpen, onClose }) {
  const ad = useSelector(selectAd)
  const language = useSelector(selectLanguage)
  return (<>
    {isOpen && <Overlay>
      <div className={style.viewModal}>
        <div className={style.close}>
          <img src={close_ic} onClick={onClose} />
        </div>
        <div className={style.content}>
          <div className={style.top}>
            <h1>{ad?.title}</h1>
            <p>{ad?.description}</p>
          </div>
          <div className={style.bottom}>
            <img src={ad?.image} />
            <div className={style.btn}>
              <PrimaryButton
                onClick={onClose}
                style={{ width: "345px" }}
              >{constants[language].cancel}</PrimaryButton>
            </div>
            <div className={style.mobileBtn}>
              <PrimaryButton
                onClick={onClose}
                style={{ width: "100%" }}
              >{constants[language].cancel}</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Overlay>}
  </>
  );
}