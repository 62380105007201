export const german = {
    upload_music: "Musik Hochladen",
    upload_video: "Video Hochladen",
    songs: "Lieder",
    playlist: "Meine Playlist",
    videos: "Videos",
    search: "Suche",
    clear: "Löschen",
    ads: "Werbung",
    logout: "Abmelden",
    "Top Searched songs": "Meistgesuchte Lieder",
    "Upload Music": "Musik Hochladen",
    "Playlist": "Meine Playlist",
    "Ads": "Werbung",
    "Upload Video": "Video Hochladen",
    "Videos": "Videos",
    start_upload: "Beginnen Sie mit dem Hochladen einer Datei",
    upload_description: "Alle in Projekten verwendeten Assets werden hier gespeichert. Beginnen Sie mit dem Erstellen, indem Sie Ihre Dateien hochladen.",
    upload_btn: "Hochladen",
    no: "Nr.",
    title: "Titel",
    artist: "Künstler",
    language: "Sprache",
    action: "Aktion",
    add_to_playlist: "Zu Meiner Playlist Hinzufügen",
    remove_from_playlist: "Aus Meiner Playlist Entfernen",
    sr: "Nr.",
    no_data: "Keine Daten Gefunden",
    created_on: "Erstellt Am",
    expired_on: "Abgelaufen Am",
    status: "Status",
    loading: "Daten Werden Geladen ...",
    actions: "Aktionen",
    create_ad: "Eine Anzeige Erstellen",
    no_data_search: "Keine Lieder gefunden. Geben Sie den Liedtitel UND den Künstlernamen in die Suche ein",
    search_placeholder: "Künstler, Lieder, Alben suchen...",
    search_placeholder_video: "Videos suchen...",
    create_ad_description: "Fügen Sie Details hinzu, um Ihre Anzeige zu veröffentlichen.",
    click_upload: "Klicken Sie zum Hochladen",
    drag_drop: "oder ziehen und ablegen",
    file_type: "SVG, PNG, JPG oder GIF (max. 800x400px)",
    ad_title: "Anzeigentitel",
    description: "Beschreibung",
    ad_expiry: "Ablauf der Anzeige",
    characters: "Zeichen",
    cancel: "Abbrechen",
    publish: "Veröffentlichen",
    ad_description: "Anzeigenbeschreibung",
    active: "Aktiv",
    close: "Geschlossen",
    // customer
    home: "Startseite",
    paytip: "Trinkgeld Geben",
    "New Releases": "Neuerscheinungen",
    recent_added: "Kürzlich Hinzugefügt",
    close_ad: "Anzeige Schließen",
    "Home": "Startseite",
    "Search Songs": "Lieder Suchen",
    "Search Playlist": "Playlist Suchen",
    "Paytip": "Trinkgeld Geben",
    "Contact Us": "Kontaktieren Sie Uns",
    leave_a_tip: "Geben Sie ein Trinkgeld über PayPal",
    leave_a_tip_description: "Unterstützen Sie unsere App, indem Sie freiwillig ein Trinkgeld über PayPal geben",
    pay_btn: "Mit Stripe Bezahlen",
    powered_by: "Powered by Felix",
    upload_new_btn: "Neu Hochladen",
    thumbnail: "Miniaturbild",
    payment_required: "Um das vollständige Video anzusehen, müssen Sie eine Zahlung vornehmen.",
    contact: "Kontaktieren Sie Uns",
    newestToOldest: "Neueste Zuerst",
    oldestToNewest: "Älteste Zuerst",
    atoZ: "A bis Z",
    ztoA: "Z bis A",
    sort_by: "Sortieren Nach",
    "Legal Notice": "Impressum",
    legal_notice: "Impressum",
    responsible_for: "Verantwortlich für diese Seite",
    desclaimer: "Haftungshinweis",
    desclaimer_desc: "Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.",
    privacy_policy: "Datenschutzerklärung",
    privacy_policy_desc: "Den Schutz Ihrer persönlichen Daten nehmen wir sehr ernst und halten uns streng an alle geltenden Gesetze und Vorschriften zum Datenschutz, insbesondere an die Datenschutzgrundverordnung, (DSGVO), das Bundesdatenschutzgesetz (BDSG) und das Telemediengesetz (TMG). Die folgenden Erläuterungen geben Ihnen einen Überblick darüber, wie wir diesen Schutz sicherstellen und welche Daten wir zu welchem Zweck verarbeiten.",
    usage_data: "Nutzungsdaten",
    usage_data_descriptions: [
        "Bei jedem Zugriff auf die Webseite und bei jedem Abruf einer Datei, werden automatisch über diesen Vorgang allgemeine Daten in einer Protokolldatei gespeichert. Die Speicherung dient ausschließlich systembezogenen und statistischen Zwecken (auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO), sowie in Ausnahmefällen zur Anzeige von Straftaten (auf Grundlage von Art. 6 Abs. 1 Buchst. e) DSGVO). ",
        "Eine Weitergabe der Daten an Dritte oder eine sonstige Auswertung findet nicht statt, es sei denn, es besteht eine gesetzliche Verpflichtung dazu (Art. 6 Abs. 1 Buchst. e) DSGVO)."],
    usage_data_bullets_heading: "Im Einzelnen wird über jeden Abruf folgender Datensatz gespeichert",
    usage_data_bullets: [
        "Name der abgerufenen Datei",
        "Datum und Uhrzeit des Abrufs",
        "Übertragene Datenmenge",
        "Meldung, ob der Abruf erfolgreich war",
        "Beschreibung des Typs des verwendeten Webbrowsers",
        "verwendetes Betriebssystem",
        "die zuvor besuchte Seite",
        "Provider",
        "Ihre IP-Adresse"
    ],
    personal_data: "Personenbezogene Daten",
    personal_data_desc: "Personenbezogene Daten werden nur dann verarbeitet, wenn dies gesetzlich gestattet ist, oder Sie Ihre Einwilligung erteilt haben.",
    specifically: "Im Einzelnen",
    personal_data_bullets: [
        {
            heading: "Kontakt",
            descriptions: [
                "Wenn Sie mit uns in Kontakt treten, speichern wir Ihre Daten auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO zum Zwecke der Bearbeitung Ihrer Anfrage, sowie für den Fall, dass eine weitere Korrespondenz stattfinden sollte."
            ]
        },
        {
            heading: "Kommentarfunktion",
            descriptions: [
                "Sie haben die Möglichkeit, individuelle Kommentare zu einzelnen, auf unsrer Webseite veröffentlichten Blog oder Gästebuch Beiträgen zu hinterlassen.",
                "Hinterlassen Sie einen solchen Kommentar, wird zu dem Inhalt des Kommentars selbst, auch der Zeitpunkt der Kommentareingabe gespeichert und veröffentlicht.",
                "Darüber hinaus wird auch Ihre IP-Adresse protokolliert. Dies geschieht zu Nachweis- und Dokumentationszwecken für den Fall, dass wir auf Grund Ihres Kommentars wegen einer Rechtsverletzung in Anspruch genommen werden sollten. Die Datenverarbeitung ist daher zur Wahrung unserer berechtigten Interessen nach Art. 6 Abs. 1 Buchst. f) DSGVO erforderlich.",
                "Eine Weitergabe Ihrer personenbezogenen Daten an Dritte folgt nur, sofern wir hierzu gesetzlich verpflichtet sind (dann auf Basis von Art. 6 Abs. 1 Buchst. e) DSGVO), oder dies zur Wahrung der berechtigten Interessen des Dritten erforderlich ist (dann auf Basis von Art. Art. 6 Abs. 1 Buchst. f) DSGVO)."
            ]
        }
    ],
    cookies: "Cookies",
    cookies_descriptions: [
        "Wir setzen in einigen Bereichen unserer Webseite sogenannte Cookies ein. Durch solche Dateielemente kann Ihr Computer als technische Einheit während Ihres Besuchs auf dieser Webseite identifiziert werden, um Ihnen die Verwendung unseres Angebotes auch bei Wiederholungsbesuchen zu erleichtern.",
        "Sie haben aber in der Regel die Möglichkeit, Ihren Internetbrowser so einzustellen, dass Sie über das Auftreten von Cookies informiert werden, so dass Sie diese zulassen oder ausschließen, beziehungsweise bereits vorhandene Cookies löschen können.",
        "Bitte verwenden Sie die Hilfefunktion Ihres Internetbrowsers, um Informationen zu der Änderung dieser Einstellungen zu erlangen. Ich weise darauf hin, dass einzelne Funktionen meiner Webseite möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.",
        "Cookies erlauben nicht, dass ein Server private Daten von Ihrem Computer oder die von einem anderen Server abgelegten Daten lesen kann. Sie richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.",
        "Wir stützen den Einsatz von Cookies auf Art. 6 Abs. 1 Buchst. f) DSGVO: die Verarbeitung erfolgt zur Verbesserung der Funktionsweise unserer Webseite. Sie ist daher zur Wahrung unserer berechtigten Interessen erforderlich."
    ],
    usage_social_media: "Verwendung der Social Plugins von Facebook, Twitter, Google+ und Instagram",
    usage_social_media_descriptions: [
        "Der Schutz Ihrer personenbezogenen Daten ist uns sehr wichtig. Deshalb verzichten wir auf die direkte Implementierung der verschiedenen Social Plugins.",
        "Auf unseren Seiten setzen wir lediglich Links auf diese sozialen Netzwerke. Erst wenn Sie den Links folgen, werden die entsprechenden Social Plugins aktiviert. ",
        "Bitte beachten Sie, dass es erst dann zu einer Übermittlung von Daten kommt, wenn Sie die entsprechenden Social Plugins durch einen Klick auf den korrespondierenden Link aktiviert haben. Allein der Besuch unserer Seiten lässt keine Datenübermittlung aus. "
    ],
    usage_social_media_bullets_heading: "Aktivieren Sie die Datenübermittlung durch Betätigen des entsprechenden Links kommt es zu folgender Datenübertragung",
    usage_social_media_bullets: [
        {
            heading: "Facebook",
            descriptions: [
                `Auf unserer Seite sind dann Plugins des sozialen Netzwerks Facebook (Facebook Inc., 1601 Willow Road, Menlo Park, CA 94025, USA) integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem "Like-Button" ("Gefällt mir") auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier: http://developers.facebook.com/docs/plugins/.`,
                `Es wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook "Like-Button" anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, Können Sie die Inhalte unserer Plattform auf Ihren Facebook-Account verlinken. Dadurch kann Facebook den Besuch unserer Seite Ihrem Account zuordnen.`,
                `Wir weisen darauf hin, dass wir als Seitenbetreiber keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter http://de-de.facebook.com/policy.php.`,
                `Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seite Ihrem Facebook-Account zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Account aus, oder aktivieren Sie die Social Plugins nicht.`
            ]
        },
        {
            heading: "Google+1",
            descriptions: [
                "Auf unserer Seite sind dann Funktionen des Dienstes Google+ eingebunden. Diese Funktionen werden angeboten durch die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. ",
                "Durch das Benutzen von Google+ und der Google-Schaltfläche baut der Browser eine direkte Verbindung mit den Servern von Google auf. Der Inhalt der Google-Schaltfläche wird von Google direkt an seinen Browser übermittelt und von diesem in die Webseite eingebunden.",
                "Wir haben keinen Einfluss auf den Umfang der Daten, die Google mit der Schaltfläche erhebt.",
                "Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre Können Sie Googles Datenschutzhinweisen zu der Google-Schaltfläche entnehmen: http://www.google.com/intl/de/+/policy/+1button.html und den FAQ: http://www.google.com/intl/de/+1/button/."
            ]
        },
    ],
    use_pulgin_youtube: "Verwendung der Social Plugins von YouTube",
    use_pulgin_youtube_descriptions: [
        "Unsere Webseite verweist durch Links zudem auf die Seite des sozialen Netzwerkes YouTube. Wir betreiben auch hier eine Social Media Seite.",
        "Wenn Sie auf einen Link zu YouTube klicken, werden Sie auf die jeweilige externe Seite bei YouTube weitergeleitet. Sind Sie zudem als Mitglied bei YouTube eingeloggt, kann der Betreiber, die YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066 USA Ihren Besuch auf unserer Seite Ihrem jeweiligen Nutzer-Account zuordnen. Hierbei weisen wir darauf hin, dass die YouTube LLC zum Konzernverbund der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA gehört.",
        "Wenn Sie nicht möchten, dass YouTube über Ihren Besuch auf unserer Internetseite Daten sammelt und speichert, müssen Sie sich vor dem Klick auf den Link aus Ihrem YouTube-Account ausloggen.",
        "Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung Ihrer Daten durch YouTube entnehmen Sie bitte der entsprechenden Datenschutzerklärung, die Sie hier finden: http://www.google.de/intl/de/policies/privacy/ ",
        "Wir stützen den Einsatz von YouTube auf Art. 6 Abs. 1 Buchst. a) DSGVO. Mit Betätigung des Links unter gleichzeitiger Anmeldung bei YouTube erteilen Sie uns Ihre Einwilligung zur Datenverarbeitung. Jedenfalls ist die Datenverarbeitung nach Art. 6 Abs. 1 Buchst. f) DSGVO zulässig; mit Betätigung des Links unter gleichzeitiger Anmeldung im jeweiligen Sozialen Netzwerk erfolgt die Datenverarbeitung zu Werbezwecken und liegt daher in unserem berechtigten Interesse."
    ],
    usage_google_utility: "Verwendung von Google Web Fonts, Google Maps und OpenStreetMap",
    usage_google_utility_bullets: [
        {
            heading: "Verwendung von Google Web Fonts und Google Maps",
            descriptions: [
                "Auf unserer Webseite werden externe Schriften, sog. Google Web Fonts, verwendet. Zudem benutzen wir Google Maps, um für Sie den Anfahrtsweg zu unserem Unternehmen darzustellen und Ihnen die Planung der Anfahrt zu vereinfachen. ",
                "Auch hierbei greifen wir auf Dienste der Google Inc. 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA zurück. ",
                "Dazu lädt beim Aufrufen unserer Seite Ihr Browser die benötigten Informationen vom Google-Server in den USA in ihren Browsercache. Dies ist notwendig damit auch Ihr Browser eine optisch verbesserte Darstellung unserer Texte anzeigen kann, bzw. die Karte auf unserer Webseite dargestellt wird.",
                "Hierdurch wird an den Google-Server übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird Ihre IP-Adresse von Google gespeichert.",
                "Weitergehende Informationen zu Google Web Fonts finden sie unter https://developers.google.com/fonts/faq?hl=de-DE&csw=1",
                "Weitergehende Informationen zu Google Maps finden sie unter https://www.google.com/intl/de_de/help/terms_maps.html",
                "Allgemeine Informationen zum Thema Datenschutz bei Google finden sie unter www.google.com/policies/privacy/"
            ]
        },
        {
            heading: "Nutzung von Google Analytics",
            descriptions: [
                "Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. Google Analytics verwendet Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.",
                "Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",
                "Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.",
            ]
        }
    ],
    duration_storage: "Dauer der Speicherung",
    duration_storage_desc: `Wir speichern Ihre Daten so lange, wie es für eine abschliessende Bearbeitung Ihres Anliegens notwendig ist. Ausgenommen hiervon sind Daten, für die gesetzliche oder anderweitig vorgeschriebene Aufbewahrungspflichten bestehen; diese werden für die Dauer der jeweiligen Aufbewahrungsfrist gespeichert und im Anschluss routinemässig gelöscht."`,
    rights_data_subject: "Betroffenenrechte",
    rights_data_subject_bullets: [
        {
            heading: "Auskunftsrecht",
            description: "Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.",
            footer: "Senden Sie hierfür bitte einfach eine E-Mail an bobfel (at) arcor.de"
        },
        {
            heading: "Berichtigung/Löschung/Einschränkung der Verarbeitung",
            description: "Des Weiteren haben Sie das Recht, von uns zu verlangen, dass",
            bullets: [
                "Sie betreffende unrichtige personenbezogene Daten unverzüglich berichtigt werden (Recht auf Berichtigung);",
                "Sie betreffende personenbezogene Daten unverzüglich gelöscht werden (Recht auf Löschung) und",
                "die Verarbeitung eingeschränkt wird (Recht auf Einschränken der Verarbeitung)."
            ],
            footer: "Senden Sie hierfür bitte einfach eine E-Mail an bobfel (at) arcor.de"
        },
        {
            heading: "Recht auf Datenübertragbarkeit",
            description: "Sie haben das Recht, Sie betreffende personenbezogene Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln.",
            footer: "Senden Sie hierfür bitte einfach eine E-Mail an bobfel (at) arcor.de"
        },
        {
            heading: "Widerrufsrecht",
            description: "Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmässgkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.",
            footer: "Senden Sie hierfür bitte einfach eine E-Mail an bobfel (at) arcor.de"
        },
        {
            heading: "Widerspruchsrecht",
            description: "Ist die Verarbeitung Sie betreffender personenbezogener Daten für die Wahrnehmung einer Aufgabe, die im Öffentlichen Interesse liegt (Art. 6 Abs. 1 Buchst. e) DSGVO) oder zur Wahrung unserer berechtigten Interessen (Art. 6 Abs. 1 Buchst. f) DSGVO) erforderlich, steht Ihnen ein Widerspruchsrecht zu.",
            footer: "Senden Sie hierfür bitte einfach eine E-Mail an bobfel (at) arcor.de"
        },
        {
            heading: "Beschwerderecht",
            description: "Sind Sie der Ansicht, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie unbeschadet anderweitiger Rechtsbehelfe das Recht auf Beschwerde bei einer Aufsichtsbehörde.",
        }
    ],
    security: "Sicherheit",
    security_desc: "Für die Sicherheit Ihrer Daten verwenden wir modernste Internettechnologien. Für eine sichere Speicherung Ihrer Daten werden unsere Systeme durch Firewalls geschützt, die unberechtigte Zugriffe von außen verhindern.",
    video_selected: "Video ausgewählt",
    "Reports": "Berichte",
    video_title: "Videotitel",
    downloads: "Herunterladungen",
    views: "Ansichten",
    uploaded_on: "Hochgeladen am",
    export_to_csv: "Als CSV exportieren",
    reports: "Berichte",
    download: "Herunterladen",
    download_by: "Heruntergeladen von",
    download_on: "Heruntergeladen am",
    view_by: "Angesehen von",
    view_on: "Angesehen am",
}