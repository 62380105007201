import styles from "../../../styles/page-styles/customer/contact-us.module.scss";
import { TextField } from '../../../components/general/input/textfield'
import { PrimaryButton } from '../../../components/general/input/primary-button'
import contactImg from '../../../assets/images/contactUs.png'
import { useEffect, useState } from "react";
import { constants } from "../../../constants";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateTab } from "../../../redux/slice/ui/ui-slice";
import { contactUsAsync } from "../../../redux/slice/auth/auth-slice";
export function ContactUs() {
    const language = useSelector(selectLanguage);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const onSubmit = () => {

        setErrors({});

        if (name && email && address && message) {
            dispatch(contactUsAsync({ name, email, address, message })).then((res) => {
                setName("");
                setEmail("");
                setAddress("");
                setMessage("");
            });
        }
        else {
            const errors = {};
            if (!name) {
                errors.name = "Name is required";
            }
            if (!email) {
                errors.email = "Email is required";
            }
            if (!address) {
                errors.address = "Address is required";
            }
            if (!message) {
                errors.message = "Message is required";
            }

            setErrors(errors);
        }
    }
    useEffect(() => {
        dispatch(updateTab({ tab: 'Contact Us' }));
    }, [])
    return <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.form}>
                {/* <h1 className={styles.heading}>Contact Us</h1> */}
                <TextField placeholder={"Name"} type={"text"} value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={errors.name}
                    name="name"
                />
                <TextField placeholder={"Email"} type={"email"} value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={errors.email}
                    name="email"
                />
                <TextField placeholder={"Address"} type={"text"} value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    error={errors.address}
                    name="address"
                />
                <div className={styles.textArea}>

                    {/* <label>{constants[language].description}</label> */}
                    <textarea
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        placeholder="Message"
                    />
                    {errors["message"] && <div className={styles.error}>{errors["message"]}</div>}
                    <div className={styles.instruction}>{message?.length}/300 {constants[language].characters}</div>
                </div>
                <button className={styles.button}
                onClick={onSubmit}
                >Submit</button>
            </div>
            <div className={styles.image}>
                <img src={contactImg} alt="contact-us" />
            </div>
        </div>
    </div>
}