import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Signup } from "../pages/signup";
import { Login } from "../pages/login";
import { Admin } from "../pages/admin";
import { UploadMusic as AdminUploadMusic } from "../pages/admin/upload-music";
import { UploadVideo as AdminUploadVideo } from "../pages/admin/upload-video";
import { Songs as AdminSongs } from "../pages/admin/songs";
import { Videos as AdminVideos } from "../pages/admin/videos";
import { Playlist as AdminPlaylist } from "../pages/admin/playlist";
import { Ads as AdminAds } from "../pages/admin/ads";
import { Customer } from "../pages/customer";
import { Home } from "../pages/customer/home";
import { Songs } from "../pages/customer/songs";
import { Playlist } from "../pages/customer/playlist";
import { Videos } from "../pages/customer/videos";
import { PayTip } from "../pages/customer/paytip";
import { useSelector } from "react-redux";
import { selectToken } from "../redux/slice/auth/auth-slice";
import { isValidToken } from "../utils/valid-token";
import { ForgotPassword } from "../pages/forgot-password";
import ProtectedRoute from "./protectedRoute";
import { NotFound } from "../pages/not-found";
import { ContactUs } from "../pages/customer/contact-us";
import { LegalNotice } from "../pages/legal-notice";
import { Reports } from "../pages/admin/reports";
function RoutesContainer() {
  const token = useSelector(selectToken)
  useEffect(() => {
    if (token) {
      if (!isValidToken(token)) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
  }, [token])
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path='/signup' element={<Signup />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/admin' element={<Admin />} >
            <Route path='' element={<AdminUploadMusic />} />
            <Route path='upload-video' element={<AdminUploadVideo />} />
            <Route path='songs' element={<AdminSongs />} />
            <Route path='playlist' element={<AdminPlaylist />} />
            <Route path='videos' element={<AdminVideos />} />
            <Route path='ads' element={<AdminAds />} />
            <Route path='legal-notice' element={<LegalNotice />} />
            <Route path='reports' element={<Reports />} />
            <Route path='*' element={<NotFound/>} />
          </Route>
          <Route path='/customer' element={<Customer />} >
            <Route path='' element={<Home />} />
            <Route path='songs' element={<Songs />} />
            <Route path='playlist' element={<Playlist />} />
            <Route path="videos" element={<Videos />} />
            <Route path='paytip' element={<PayTip />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path='legal-notice' element={<LegalNotice />} />
            <Route path='*' element={<NotFound/>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesContainer;
