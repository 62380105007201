import styles from '../../../styles/component-styles/customer/layout.module.scss';
import en from '../../../assets/icons/en.svg';
import fr from '../../../assets/icons/fr.svg';
import es from '../../../assets/icons/es.svg';
import de from '../../../assets/icons/de.svg';
import dd from "../../../assets/icons/drop-down.svg";
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import check_ic from '../../../assets/icons/check-white.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage, setLanguageAsync } from '../../../redux/slice/language/laguage.slice';
export function LanguageSelector() {
    const languages = [
        {name: 'En', flag: en, value: 'en'},
        {name: 'Fr', flag: fr, value: 'fr'},
        {name: 'Es', flag: es, value: 'es'},
        {name: 'De', flag: de, value: 'de'},
    ]
    const language = useSelector(selectLanguage)
    const dropdownRef = useRef(null);
    const selectorRef = useRef(null);
    const dispatch = useDispatch()
    const handleClick = (e) => {
        if (dropdownRef.current) {
            if (dropdownRef.current.style.display === 'flex') {
                dropdownRef.current.style.display = 'none';
            }
            else{
                dropdownRef.current.style.display = 'flex';
            }
        }
        else {
            dropdownRef.current.style.display = 'none';
        }
    }
    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (selectorRef.current) {
                if (!selectorRef.current.contains(e.target)) {
                    dropdownRef.current.style.display = 'none';
                }
            }
        });
    }, []);
    
    return (
            <div className={styles.selector} ref={selectorRef}>
                <span className={styles.selected} onClick={handleClick} id="1">
                    <img src={languages.find(l => l.value === language).flag} id="1"/>
                    <span className={styles.name} id="1">{languages.find(l => l.value === language).name}</span>    
                </span>
                <img src={dd} onClick={handleClick} id="1"/>
                <div className={styles.dropdown} ref={dropdownRef}>
                    {languages.map((language1, index) => (
                        <div key={index} onClick={() => {
                            dispatch(setLanguageAsync({language:language1.value}))
                            handleClick()
                        }}

                            className={language === language1.value ? styles.active : ''}>
                            <span>
                            <img src={language1.flag}/>
                            {language1.name}
                            </span>
                            <img src={check_ic}/>
                        </div>
                    ))}
                </div>
            </div>
        );
}