import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import laptop from "../../assets/images/laptop.svg";
import { PrimaryButton } from "../../components/general/input/primary-button";
import { TextField } from "../../components/general/input/textfield";
import { Footer } from "../../components/login/footer";
import { forgotPasswordAsync, loginUserAsync } from "../../redux/slice/auth/auth-slice";
import styles from "../../styles/page-styles/forgot-password/forgot-password.module.scss";
import { validateForgotPassword } from "../../utils/validate-forgot-password";
export function ForgotPassword() {
    const [userData, setUserData] = useState({ email: "", password: "", confirmPassword: "" });
    const [errors, setErrors] = useState({ email: "", password: "", confirmPassword: "" });
    const dispatch = useDispatch();
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    }
    const handleReset = () => {
        const { errors, noError } = validateForgotPassword(userData);
        setErrors(errors);
        if (noError)
            dispatch(forgotPasswordAsync({ email: userData?.email, password: userData?.password }));
    }

    return <div className={styles.login}>
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.form}>
                    <div className={styles.top}>
                        <h1 className={styles.title}>Forgot Password</h1>
                        <p className={styles.subtitle}>Enter your email and new password</p>
                    </div>
                    {/* <div className={styles.googleSignIn}>
                        <SecondaryButton className={styles.googleButton}>
                            <img src={google} alt="google" className={styles.googleIcon} />
                            Sign in with Google</SecondaryButton>
                    </div> */}
                    {/* <Divider text={"or"} /> */}
                    <div className={styles.formFields}>
                        <TextField label="Email" placeholder="Enter your email" type="email"
                            name="email"
                            onChange={handleInputChange}
                            error={errors.email}
                            value={userData.email}
                        />
                        <TextField label="New Password" placeholder="Enter new password" type="password"
                            name="password"
                            onChange={handleInputChange}
                            error={errors.password}
                            value={userData.password}
                        />
                        <TextField label="Confirm New Password" placeholder="Confirm new password" type="password"
                            name="confirmPassword"
                            onChange={handleInputChange}
                            error={errors.confirmPassword}
                            value={userData.confirmPassword}
                        />
                    </div>
                    {/* <div className={styles.terms}>
                        <div className={styles.rememberMe}>
                        <input type="checkbox" id="terms" />
                        <label htmlFor="terms">Remeber me</label>
                        </div>
                        <Link to='/forgot-password'
                        className={styles.forgotPassword}
                        >Forgot password?</Link>
                    </div> */}
                    <PrimaryButton
                        onClick={handleReset}
                    >Reset Password</PrimaryButton>
                    <p className={styles.bottom}>Remembered your password? <Link to={'/'}>Login</Link></p>
                </div>
            </div>
            <div className={styles.right}>
                <span className={styles.eclipse}>

                </span>
                <img src={laptop} alt='laptop' />
            </div>
        </div>
        <Footer />
    </div>

}