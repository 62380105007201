export function validateForgotPassword(data) {
    let noError = true;
    let errors = { email: "", password: "", confirmPassword: "" };
    if (!data.email) {
        errors.email = "Email is required";
        noError = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
        errors.email = "Email is invalid";
        noError = false;
    }

    if (!data.password) {
        errors.password = "Password is required";
        noError = false;
    }
    if (!data.confirmPassword) {
        errors.confirmPassword = "Confirm Password is required";
        noError = false;
    }
    if (data.password !== data.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password should be same";
        noError = false;
    }
    return { errors, noError };
    
}