import { useEffect, useState } from 'react';
import styles from '../../../styles/component-styles/admin/upload.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import { selectNumberOfUploads, selectUploadingSongs, selectLastUpdated, selectStartingId, cancelUploadSongAsync, selectLastId, selectProgress } from '../../../redux/slice/song/song-slice';
export function UploadProgress({onDelete=()=>{}}){
    const isUploading = useSelector(selectUploadingSongs)
    const progress = useSelector(selectProgress)
    const lastDate = new Date(useSelector(selectLastUpdated))
    const totalSongs = useSelector(selectNumberOfUploads)
    const handleDelete = ()=>{
        onDelete()
    }
    return (
        <div className={styles.uploadInfoModal}>
            <div className={styles.content}>
                
                {isUploading?<div className={styles.progress}>
                    <p className={styles.uploading}>{Number(progress).toFixed(2)}% Uploading...</p>
                </div>:<div className={styles.action}>
                    <p className={styles.lastDate}>Last Updated: {lastDate.toLocaleString()}</p>
                    <p className={styles.totalSongs}>Total Songs: {totalSongs}</p>
                    <button className={styles.delete} onClick={handleDelete}>Delete</button>
                </div>}
            </div>
        </div>
    )
}