import styles from '../../../styles/component-styles/general/table.module.scss';
import trash_ic from '../../../assets/icons/trash.svg';
import show_ic from '../../../assets/icons/show.svg';
import eye_hide_ic from '../../../assets/icons/eye_hidden.svg';
import { convertDateFormat } from '../../../utils/convert-date-format';
import no_data from "../../../assets/images/no_data.svg"
import loader from "../../../assets/gifs/loader.webp"
import { constants } from '../../../constants';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { useSelector } from 'react-redux';
export function AdsTable({ads, onView=()=>{}, onRemove=()=>{}}){
    const language = useSelector(selectLanguage)
    return <table className={styles.adsTable}>
        <thead>
            <tr>
                <th className={styles.head}>{constants[language].sr}</th>
                <th className={styles.head}>{constants[language].title}</th>
                <th className={styles.head}>{constants[language].created_on}</th>
                <th className={styles.head}>{constants[language].expired_on}</th>
                <th className={styles.head}>{constants[language].status}</th>
                <th className={styles.head}>{constants[language].actions}</th>

            </tr>
        </thead>
        <tbody>
            
            <div className={styles.line}></div>
            {ads && ads.map((ad, index) => {
                const status = new Date(ad.expiredAt) < new Date() ? 'Closed' : 'Active'
            return <tr key={index}
            >
                <td><div className={`${styles.no}`}>{index + 1}</div></td>
                <td><div className={styles.title}>
                    <img src={ad.image} alt="ad" />
                    <div className={styles.text}>
                        <h1>{ad.title}</h1>
                        <p>{ad.description.length > 50 ? ad.description.substring(0, 50) + '...' : ad.description}</p>
                    </div>
                    
                    
                    </div></td>
                <td><div className={styles.date}>{convertDateFormat(ad.createdAt)}</div></td>
                <td><div className={styles.date}>{convertDateFormat(ad.expiredAt)}</div></td>
                <td><div className={`${styles.status} ${status === 'Active'?styles.green:styles.red}`}>
                    {constants[language][status.toLocaleLowerCase()]}</div></td>
                <td><div className={styles.action}>
                    <img src={trash_ic} alt="action" 
                    onClick={()=>{onRemove(ad._id)}}
                    />
                    <img src={show_ic} alt="action" onClick={()=>onView(ad._id)}/>
                    </div></td>
            </tr>})}
            {ads && ads?.length == 0 && <tr><td colSpan={6}>
                <div className={styles.noData}>
                    <img src={no_data} />
                    <p>{constants[language].no_data}</p>
                </div>
            </td></tr>}
            {!ads && <tr><td colSpan={6}>
                <div className={styles.noData}>
                    <img src={loader} />
                    <p>{constants[language].loading}</p>
                </div>
            </td></tr>}
        </tbody>
    </table>
}