export const formatAgo = function (createdAt) {
  const timeAgo = new Date() - new Date(createdAt);
  if (timeAgo <= 0) {
    return "Just now";
  }
  if (timeAgo < 1000 * 60) {
    const seconds = Math.round(timeAgo / 1000);
    return `${seconds} ${seconds > 1 ? "seconds" : "second"} ago`;
  }
  if (timeAgo < 60000 * 60) {
    const minutes = Math.round(timeAgo / 1000 / 60);
    return `${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
  }
  if (timeAgo < 3600000 * 24) {
    const hours = Math.round(timeAgo / 1000 / 60 / 60);
    return `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  }
  const daysAgo = Math.round(timeAgo / 1000 / 60 / 60 / 24);
  if (daysAgo < 7) {
    return `${daysAgo} ${daysAgo > 1 ? "days" : "day"} ago`;
  }
  if (daysAgo < 30) {
    const weeks = Math.round(daysAgo / 7);
    return `${weeks} ${weeks > 1 ? "weeks" : "week"} ago`;
  }
  if (daysAgo < 365) {
    const months = Math.round(daysAgo / 30);
    return `${months} ${months > 1 ? "months" : "month"} ago`;
  }
  const years = Math.round(daysAgo / 365);
  return `${years} ${years > 1 ? "years" : "year"} ago`;
};
