import styles from '../../../styles/component-styles/general/table.module.scss';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { constants } from '../../../constants';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import no_data from "../../../assets/images/no_data.svg"
import loader from "../../../assets/gifs/loader.webp"
import { convertDateFormat } from '../../../utils/convert-date-format';
import { convertDatetimeFormat } from '../../../utils/convert-datetime-formet';
export function DownloadInfoTable({ videoInfo, onSort = () => { }, sortBy, setCurrentPage, currentPage, totalPages}) {
    const language = useSelector(selectLanguage)
    const [ref, inView] = useInView({ triggerOnce: false })
    useEffect(() => {
        if (inView) {
            if (currentPage < totalPages)
                setCurrentPage(currentPage + 1)
        }
    }, [inView])
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th className={styles.head}>{constants[language].no}</th>
                    <th className={styles.head}>
                        <div className={styles.header}>
                            {constants[language].download_by}
                            <div className={styles.sorting}>
                                <button className={styles.sortingBtn + " " + (sortBy == 'emailAsc' ? styles.active : "")}
                                    onClick={() => onSort('emailAsc')}
                                >
                                    ▲
                                </button>
                                <button className={styles.sortingBtn + " " + (sortBy == 'emailDesc' ? styles.active : "")}
                                    onClick={() => onSort('emailDesc')}>
                                    ▼
                                </button>
                            </div>
                        </div>
                    </th>
                    <th className={styles.head}>
                        <div className={styles.header}>
                            {constants[language].download_on}
                            <div className={styles.sorting}>
                                <button className={styles.sortingBtn + " " + (sortBy == 'createdAtAsc' ? styles.active : "")}
                                    onClick={() => onSort('createdAtAsc')}>
                                
                                    ▲
                                </button>
                                <button className={styles.sortingBtn + " " + (sortBy == 'createdAtDesc' ? styles.active : "")}
                                    onClick={() => onSort('createdAtDesc')}>
                                    ▼
                                </button>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "fit-content" }}>
                    <td colSpan={3}>
                        <div className={styles.line}></div>
                    </td>

                </tr>
                {videoInfo && videoInfo.map((video, index) => {
                    return (
                        <tr key={video._id}
                            ref={index === videoInfo.length - 1 ? ref : null}
                        >
                            <td><div className={styles.no}>{index + 1}</div></td>
                            <td><div className={styles.title}>
                                <span>{video.email}</span>
                            </div></td>
                           
                            <td><div className={styles.uploadedOn}>{convertDatetimeFormat(video.createdAt)}</div></td>
                        </tr>
                    )
                })}
                {videoInfo && videoInfo?.length == 0 && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={no_data} />
                    <p>{constants[language].no_data}</p>
                </div>
            </td></tr>}
            {!videoInfo && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={loader} />
                    <p>{constants[language].loading}</p>
                </div>
            </td></tr>}
            </tbody>
        </table>
    )
}