import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updateTab } from "../../../redux/slice/ui/ui-slice";
import styles from "../../../styles/page-styles/customer/home.module.scss";
import { AdsSlider } from "../../../components/cutomer/ads-slider";
import { Table } from "../../../components/general/table";
import { selectTopSongs, topSongsAsync } from "../../../redux/slice/song/song-slice";
import { useSelector } from "react-redux";
import { constants } from "../../../constants";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
export function Home() {
    const dispatch = useDispatch();
    const topSongs = useSelector(selectTopSongs);
    useEffect(() => {
        dispatch(updateTab({ tab: 'Home' }))
        if (!topSongs) {
            dispatch(topSongsAsync({}))
        }
    }, [])
    const language = useSelector(selectLanguage)
    return <div className={styles.container}>
        <AdsSlider />
        <h1 className={styles.heading}>{constants[language].recent_added}</h1>
        <div className={styles.table}>
            <Table songs={topSongs} />
        </div>
    </div>
}