import React, { useRef, useState } from "react";
import styles from "../../../styles/component-styles/admin/upload.module.scss";
import upload_img from "../../../assets/images/upload.svg";
import upload_ic from "../../../assets/icons/upload.svg";
import { PrimaryButton } from "../../general/input/primary-button";
import { useSelector, useDispatch } from "react-redux";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import {
  cancelUploadSongAsync,
  uploadSongAsync,
  selectLastId,
  selectStartingId,
} from "../../../redux/slice/song/song-slice";
import { TextField } from "../../general/input/textfield";
import { uploadVideoAsync, selectUploadProgress } from "../../../redux/slice/video/video-slice";
import { LoaderModal } from "../../login/loader-modal";
import { VideoUploadProgressbar } from "./video-upload-progress-bar";
import { calculateVideoUploadProgress } from "../../../utils/convert-date-format";

export function UploadVideo() {
  const uploadProgress = useSelector(selectUploadProgress);
  const fileInput = useRef(null);
  const language = useSelector(selectLanguage);

  const dispatch = useDispatch();
  const handleFile = (e) => {
    if (e.target.files[0]) {
      let files = Object.values(e.target.files);
      let titles = files.map((file) => file.name.substring(0, file.name.lastIndexOf(".")));
      dispatch(uploadVideoAsync({ files, titles }));
    }
  };

  const totalProgress = calculateVideoUploadProgress(uploadProgress);
  return (
    <div className={styles.upload}>
      <LoaderModal
        isDisplayProgressBar={true}
        text="Uploading..."
        percentage={totalProgress <= 0 ? 1 : totalProgress}
        // percentage={isNaN(totalProgress) ? 0 : totalProgress}
      />
      {/* <VideoUploadProgressbar percentage={78} /> */}
      <>
        <div className={styles.content}>
          <img src={upload_img} />
          <div className={styles.text}>
            <h1>{constants[language].start_upload}</h1>
            <p>{constants[language].upload_description}</p>
          </div>
        </div>
        <div className={styles.uploadBtn} style={{ width: "100%" }}>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            ref={fileInput}
            accept="video/*"
            onChange={handleFile}
            multiple={true}
          />
          <div className={styles.btn} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              style={{ width: "350px" }}
              onClick={() => {
                fileInput.current.click();
              }}
            >
              <img src={upload_ic} />
              {constants[language].upload_btn}
            </PrimaryButton>
          </div>
          <div className={styles.mobileBtn} style={{ width: "100%" }}>
            <PrimaryButton
              style={{ width: "100%", height: "35px", fontSize: "12px" }}
              onClick={() => {
                fileInput.current.click();
              }}
            >
              <img src={upload_ic} />
              {constants[language].upload_btn}
            </PrimaryButton>
          </div>
        </div>
      </>
    </div>
  );
}
