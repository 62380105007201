import styles from "../../styles/component-styles/login/modals.module.scss";
import loader from "../../assets/gifs/loader.webp";
import { useSelector } from "react-redux";
import { selectIsLoaderOpen } from "../../redux/slice/ui/ui-slice";
export function LoaderModal({ text = "Loading...", isDisplayProgressBar = false, percentage = "80" }) {
  const isOpen = useSelector(selectIsLoaderOpen);

  return (
    <>
      {isOpen && (
        <div className={styles.overlay}>
          <div className={styles.content}>
            {!isDisplayProgressBar && (
              <>
                <img src={loader} />
                <span className={styles.text}>{text}</span>
              </>
            )}
            {/*  display the progress bar  */}
            {isDisplayProgressBar && (
              <>
                <div className={styles.progress_bar_overlay}>
                  <div
                    className={styles.inner_bar}
                    style={{
                      width: `${percentage}%`,
                    }}
                  />
                </div>
                <span className={styles.progress_text}> {`${percentage}%`} </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
