export const videos = [
    {
        _id: "1",
        title: "Lorem Ipsum",
        description: "This is a description",
        createdAt: new Date(2024, 2, 15),
        url: "https://my.hidrive.com/lnk/BY30Qj3sW",
        thumbnail:"https://www.adobe.com/express/create/thumbnail/media_184a3a28ded5926b56142bf7f41b1c6972df38f0c.png?width=750&format=png&optimize=medium",
        views: 10,
    },
    {
        _id: "2",
        title: "All I Want For Christmas Is You",
        description: "This is a description",
        createdAt: new Date(2024, 2, 15),
        url: "https://my.hidrive.com/lnk/BY30Qj3sW",
        thumbnail:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn9_JoOX4sTRHp76Vyc7Xm-MiwRSgUlkEarJRUEswjYQ&s",
        views: 48923820,
    },
    {
        _id: "3",
        title: "HIT MACHINE",
        description: "This is a description",
        createdAt: new Date(2024, 2, 15),
        url: "https://my.hidrive.com/lnk/BY30Qj3sW",
        thumbnail:"https://i.pinimg.com/736x/5b/2f/5e/5b2f5e020eb4ab271ae09641092cfddd.jpg",
        views: 5002,
    }
]