import { useSelector } from "react-redux";
import styles from "../../../styles/component-styles/general/grid.module.scss";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import trash_ic from "../../../assets/icons/trash-white.svg";
export function SelectedVideoAction({ number, onDelete, onCancel}) {
    const language= useSelector(selectLanguage)
    return <div className={styles.actionModal}>
        <p>{number} {constants[language].video_selected}</p>
        <div className={styles.action}>
            <span className={styles.delete} onClick={onDelete}>
                <img src={trash_ic}/>
            </span>
            <span className={styles.cancel} onClick={onCancel}>Cancel</span>
        </div>
    </div>;
}