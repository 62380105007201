import { combineReducers } from "redux";
import authReducer from "./slice/auth/auth-slice";
import uiReducer from "./slice/ui/ui-slice";
import songReducer from "./slice/song/song-slice"
import playlistReducer from "./slice/playlist/playlist-slice";
import adReducer from "./slice/ad/ad-slice";
import languageReducer from "./slice/language/laguage.slice";
import videoReducer from "./slice/video/video-slice"
import paymentReducer from "./slice/payment/payment-slice"
import reportsReducer from "./slice/reports/reports-slice";
const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  song: songReducer,
  playlist: playlistReducer,
  ad: adReducer,
  language: languageReducer,
  video: videoReducer,
  payment: paymentReducer,
  reports: reportsReducer
});

export default rootReducer;
