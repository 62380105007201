import React from 'react';
import styles from "../../../styles/component-styles/general/input.module.scss";
import search_ic from "../../../assets/icons/search.svg";
import { PrimaryButton } from './primary-button';
import close_ic from "../../../assets/icons/close.svg";
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { constants } from '../../../constants';
export function SearchBox({ placeholder, onSearch, onClear}) {
    const [value, setValue] = React.useState("");
    const onChange = (e) => {
        setValue(e.target.value)
    }
    const [isClicked, setIsClicked] = React.useState(false);
    const language = useSelector(selectLanguage)
    return <div className={styles.searchBox}>
        <img src={search_ic} alt="search" className={styles.icon} />
        <input type="text" placeholder={placeholder} value={value} className={styles.textBox} 
        onChange={onChange}
        onKeyDown={(e) => {
            if (e.key === "Enter") {
                onSearch(value)
                setIsClicked(true)
            }
        }}
        />
        {isClicked?
        <button className={styles.clearBtn} onClick={() => {
            setValue("")
            setIsClicked(false)
            onClear()
        }}>
            <img src={close_ic}/>
            {constants[language].clear}</button>
        :<><span className={styles.mobileSearchBtn}><PrimaryButton style={{width:"70px", fontSize:"12px", height:"30px"}} 
            onClick={() => {
                setIsClicked(true)
                onSearch(value)
            }}
        >{constants[language].search}</PrimaryButton></span>
        <span className={styles.searchBtn}><PrimaryButton style={{width:"100px"}} 
        onClick={() => {
            setIsClicked(true)
            onSearch(value)
        }}
    >{constants[language].search}</PrimaryButton></span></>}
    </div>
}