import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { updateTab } from "../../../redux/slice/ui/ui-slice";
import styles from "../../../styles/page-styles/customer/paytip.module.scss";
import { SecondaryButton } from "../../../components/general/input/secondary-button";
import { PrimaryButton } from "../../../components/general/input/primary-button";
import paypal_ic from "../../../assets/icons/stripe.png";
import paypal_img from "../../../assets/images/paypal.svg";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
export function PayTip(){
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateTab({tab: 'Paytip'}))
    }, [])
    const language = useSelector(selectLanguage)
    return <div className={styles.container}>
        <div className={styles.paytip}>
            <div className={styles.top}>
                <img src={paypal_img}/>
            </div>
            <div className={styles.bottom}>
                <div className={styles.text}>
                    <h1>{constants[language].leave_a_tip}</h1>
                    <p>{constants[language].leave_a_tip_description}</p>
                </div>
                <div className={styles.button}>
                    <PrimaryButton style={{width:"230px"}}
                    onClick={() => {
                        window.location.href = "https://paypal.me/felixentertainment?country.x=DE&locale.x=de_DE"
                    }}
                    ><img src={paypal_ic}
                    width={20}
                    />{constants[language].pay_btn}</PrimaryButton>
                </div>
                <div className={styles.mobileButton}>
                    <PrimaryButton style={{width:"190px", height:"40px", fontSize:"14px"}}
                    onClick={() => {
                        window.location.href = "https://paypal.me/felixentertainment?country.x=DE&locale.x=de_DE"
                    }}
                    ><img src={paypal_ic}
                    width={20}
                    />{constants[language].pay_btn}</PrimaryButton>
                </div>
            </div>
        </div>
    </div>
}