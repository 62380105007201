import styles from "../../styles/page-styles/login/login.module.scss"
import laptop from "../../assets/images/laptop.svg"
import { Link, useNavigate } from "react-router-dom";
import google from "../../assets/icons/google.svg"
import { Divider } from "../../components/login/divider";
import { TextField } from "../../components/general/input/textfield";
import { SecondaryButton } from "../../components/general/input/secondary-button";
import { PrimaryButton } from "../../components/general/input/primary-button";
import { Footer } from "../../components/login/footer";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAsync, logoutUser, selectToken, selectUser } from "../../redux/slice/auth/auth-slice";
import { useEffect } from "react";
import { isValidToken } from "../../utils/valid-token";
import { useState } from "react";
import { validateLoginUserData } from "../../utils/validate-login-user-data";
import { LoaderModal } from "../../components/login/loader-modal";
export function Login() {
    const [userData, setUserData] = useState({email:"", password:""});
    const [errors, setErrors] = useState({email:"", password:""});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (user && token) {
            if(isValidToken(token)){
                if(user.role === "admin"){
                    navigate("/admin");
                }
                else{
                    navigate("/customer");
                }
            }
            else{
                dispatch(logoutUser({}));
            }
        }
    }, [user, token]);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setUserData({...userData, [name]:value});
    }
    const handleLogin = () => {
        const {errors, noError} = validateLoginUserData(userData);
        setErrors(errors);
        if(noError)
            dispatch(loginUserAsync({ email:userData?.email, password:userData?.password }));
    }
    
    return <div className={styles.login}>
        
        <div className={styles.container}>
        <LoaderModal/>
            <div className={styles.left}>
                <div className={styles.form}>
                    <div className={styles.top}>
                        <h1 className={styles.title}>Log in to your account</h1>
                        <p className={styles.subtitle}>Don’t have an account? <Link to='/signup'>Sign Up</Link></p>
                    </div>
                    {/* <div className={styles.googleSignIn}>
                        <SecondaryButton className={styles.googleButton}>
                            <img src={google} alt="google" className={styles.googleIcon} />
                            Sign in with Google</SecondaryButton>
                    </div> */}
                    {/* <Divider text={"or"} /> */}
                    <div className={styles.formFields}>
                        <TextField label="Email" placeholder="Enter your email" type="email" 
                        name="email"
                        onChange={handleInputChange}
                        error={errors.email}
                        value={userData.email}
                        />
                        <TextField label="Password" placeholder="Enter your password" type="password" 
                        name="password"
                        onChange={handleInputChange}
                        error={errors.password}
                        value={userData.password}
                        />
                    </div>
                    <div className={styles.terms}>
                        <div className={styles.rememberMe}>
                        <input type="checkbox" id="terms" />
                        <label htmlFor="terms">Remember me</label>
                        </div>
                        <Link to='/forgot-password'
                        className={styles.forgotPassword}
                        >Forgot password?</Link>
                    </div>
                    <PrimaryButton
                    onClick={handleLogin}
                    >Sign In</PrimaryButton>
                </div>
            </div>
            <div className={styles.right}>
                <span className={styles.eclipse}>

                </span>
                <img src={laptop} alt='laptop' />
            </div>
        </div>
        <Footer/>
    </div>

}