import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTab } from '../../../redux/slice/ui/ui-slice';
import styles from '../../../styles/page-styles/admin/upload-music.module.scss';
import { Upload } from '../../../components/admin/upload';
import { Table } from '../../../components/general/table';
// import { songs } from './constant';
import { selectTopSongs, topSongsAsync } from '../../../redux/slice/song/song-slice';
export function UploadMusic() {
    const dispatch = useDispatch();
    const songs = useSelector(selectTopSongs);
    useEffect(() => {
        dispatch(updateTab({ tab: 'Upload Music' }))
        if(!songs) {
            dispatch(topSongsAsync({}))
        }
    }, [])
    
    return <div className={styles.uploadMusic}>
        <Upload/>
        <div className={styles.table}>
        <Table songs={songs}/>
        </div>
        </div>
}