export function validateRegisterUserData(data) {
    const errors = {};
    let noError = true;
    if (!data.email) {
        errors.email = "Email is required";
        noError = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
        errors.email = "Email is invalid";
        noError = false;
    }
    
    if (!data.password) {
        errors.password = "Password is required";
        noError = false;
    }
    if(!data.name){
        errors.name = "Name is required";
        noError = false;
    }
    if(!data.terms){
        errors.terms = "Please agree to the terms and conditions";
        noError = false;
    }
    return {errors, noError};
}