
import { useSelector } from "react-redux";
import styles from "../../../styles/component-styles/customer/layout.module.scss";
import { LanguageSelector } from "./language-selector";
import { Profile } from "./profile";
import { useEffect, useState } from "react";
import { selectTab } from "../../../redux/slice/ui/ui-slice";
import { constants } from "../../../constants";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
export function Navbar(){
    const active = useSelector(selectTab)
    const [title, setTitle] = useState(null)
    const language = useSelector(selectLanguage)
    useEffect(() => {
        if(active === "Songs"){
            setTitle("New Releases")
        }
        else{
            setTitle(active)
        }
    }, [active])
    return (
        <>
        <div className={styles.navbar}>
            
            <div className={styles.left}>
                <h1>{constants[language][title]}</h1>
            </div>
            <div className={styles.right}>
                <LanguageSelector/>
                <hr/>
                <Profile/>
            </div>
        </div>
        <hr/>
        </>
    );
}
export function MobileNavbar({openSidebar}) {
    const active = useSelector(selectTab)
    const [title, setTitle] = useState(null)
    const language = useSelector(selectLanguage)
    useEffect(() => {
        if(active === "Songs"){
            setTitle("New Releases")
        }
        else{
            setTitle(active)
        }
    }, [active])
    return (
        <>
        <div className={styles.mobileNavbar}>
            <span onClick={openSidebar}>
                <img src="https://img.icons8.com/ios/30/ffffff/menu.png"/>
            </span>
            <div className={styles.left}>
                <h1>{constants[language][title]}</h1>
            </div>
            <div className={styles.right}>
                <LanguageSelector/>
                <hr/>
                <Profile/>
            </div>
        </div>
        <hr/>
        </>
    );
}