import { useState, useRef, useEffect } from "react";
import styles from "../../../styles/component-styles/admin/modals.module.scss";
import close_ic from "../../../assets/icons/close.svg";
import upload_ic from "../../../assets/icons/upload.svg";
import { Overlay } from "./overlay";
import { TextField } from "../../general/input/textfield";
import trash_red_ic from "../../../assets/icons/trash-red.svg";
import ad1 from "../../../assets/images/ads/ad2.svg";
import { useDispatch } from "react-redux";
import { createAdAsync } from "../../../redux/slice/ad/ad-slice";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import {useSelector} from "react-redux";
export function CreateAd({ isOpen, onClose, onPublish }) {
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [expiry, setExpiry] = useState("");
    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null);
    const [fileSize, setFileSize] = useState(0);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        let isErr = false;
        setErrors({});
        if (title === "") {
            setErrors({ ...errors, title: "Title is required" });
            isErr = true;
        }
        if (description === "") {
            setErrors({ ...errors, description: "Description is required" });
            isErr = true;
        }
        if (expiry === "") {
            setErrors({ ...errors, expiry: "Expiry is required" });
            isErr = true;
        }
        if (image === null) {
            setErrors({ ...errors, image: "Image is required" });
            isErr = true;
        }
        if (isErr) return;
        const formData = new FormData();
        formData.append('file', image);
        formData.append('title',title);
        formData.append('description',description);
        formData.append('expiredAt',expiry);
        dispatch(createAdAsync({formData})).then(()=>{
            onPublish()
        })
    }
    const handleDescription = (e) => {
        if (e.target.value.length <= 300)
            setDescription(e.target.value);
    }

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleExpiry = (e) => {
        setExpiry(e.target.value);
    }

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        // Do something with the selected file
        setImage(file);
        setFileSize(file.size / 1024 / 1024);
        setPercentage(0);
        setInterval(() => {
            setPercentage((old) => {
                if (old < 100) {
                    return old + 1;
                }
                return old;
            });
        }, 100);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        // Do something with the dropped file
        setImage(file);
        setFileSize(file.size / 1024 / 1024);
        setPercentage(0);
        setInterval(() => {
            setPercentage((old) => {
                if (old < 100) {
                    return old + 1;
                }
                return old;
            });
        }, 100);
    }
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setImage(null);
        setTitle("");
        setExpiry("");
        setDescription("");
        setPercentage(0);
        setFileSize(0);
    }
        , [isOpen]);
    const language = useSelector(selectLanguage);
    return (
        <>
            {isOpen && <Overlay>
                <div className={styles.createModal}>
                    <div className={styles.top}>
                        <div className={styles.header}>
                            <h1>{constants[language].create_ad}</h1>
                            <button onClick={onClose} className={styles.close}>
                                <img src={close_ic} alt="close" />
                            </button>
                        </div>
                        <p className={styles.subtitle}>{constants[language].create_ad_description}</p>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.upload} onDrop={handleDrop} onDragOver={handleDragOver}>
                            {!image && <>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileSelect}
                                />
                                <img src={upload_ic} alt="upload"
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                />
                                <div className={styles.uploadText}>
                                    <p className={styles.line1} onClick={() => {
                                        fileInputRef.current.click();

                                    }}>
                                        {constants[language].click_upload} <span>{constants[language].drag_drop}</span>
                                    </p>
                                    <p className={styles.line2}>{constants[language].file_type}</p>
                                </div>
                            </>}
                            {errors['image'] && <p className={styles.error}>{errors['image']}</p>}
                            {image &&
                                <div className={styles.selectedImg}>
                                    <img src={URL.createObjectURL(image)} alt="ad" className={styles.img} />
                                    <div className={styles.titleAndLoader}>
                                        <div>
                                            <span className={styles.title}>{image.name}</span>
                                            <span className={styles.size}>{(fileSize < 1 ? fileSize * 1024 : fileSize).toFixed(2)} {fileSize < 1 ? "KB" : "MB"}</span>
                                        </div>
                                        <div className={styles.loader}>
                                            <div className={styles.bar}>
                                                <div className={styles.fill} style={{ width: `${percentage}%` }}></div>
                                            </div>
                                            <span>{percentage}%</span>
                                        </div>

                                    </div>
                                    <img src={trash_red_ic}
                                        className={styles.delete}
                                        onClick={() => { setImage(null); setFileSize(0); setPercentage(0) }}
                                    />
                                </div>
                            }
                        </div>
                        <div className={styles.form}>
                            <div className={styles.mobileRow}>
                                <TextField label={constants[language].ad_title} placeholder="Ashar Atique"
                                    onChange={handleTitle}
                                    error={errors["title"]}
                                />
                                <TextField label={constants[language].ad_expiry} placeholder="03/05/2024"
                                    type={"date"}
                                    onChange={handleExpiry}
                                    error={errors["expiry"]}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField label={constants[language].ad_title} placeholder="Ashar Atique"
                                    style={{ width: "400px" }}
                                    onChange={handleTitle}
                                    error={errors["title"]}
                                />
                                <TextField label={constants[language].ad_expiry} placeholder="03/05/2024"
                                    type={"date"}
                                    style={{ width: "400px" }}
                                    onChange={handleExpiry}
                                    error={errors["expiry"]}
                                />
                            </div>
                            <div className={styles.textArea}>

                                <label>{constants[language].description}</label>
                                <textarea placeholder={constants[language].ad_description} onChange={handleDescription}
                                    value={description}
                                />
                                {errors["description"] && <div className={styles.error}>{errors["description"]}</div>}
                                <div className={styles.instruction}>{description?.length}/300 {constants[language].characters}</div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button className={styles.cancel} onClick={onClose}>{constants[language].cancel}</button>
                            <button className={`${styles.publish} ${image !== null && title !== "" && expiry !== "" && description !== "" ? styles.red : ""
                                }`} onClick={handleSubmit}
                                disabled={image === null || title === "" || expiry === "" || description === ""}
                            >{constants[language].publish}</button>
                        </div>
                    </div>
                </div>
            </Overlay>}
        </>
    );
}
