import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPOST, apiGET } from "../../../apis/service";
import { toast } from "react-toastify";
export const addSongToPlaylist = createAsyncThunk(
    "playlist/add",
    async ({ songId }, thunkAPI) => {
        try {
            const response = await apiPOST("playlist-add-song", { songId });
            if (response.success){
                thunkAPI.dispatch(addSongToPlaylistSuccess(response));
                toast.success("Song added to playlist successfully!");
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const removeSongFromPlaylist = createAsyncThunk(
    "playlist/remove",
    async ({ songId }, thunkAPI) => {
        try {
            const response = await apiPOST("playlist-remove-song", { songId });
            if (response.success){
                thunkAPI.dispatch(removeSongFromPlaylistSuccess(response));
                toast.success("Song removed from playlist successfully!");
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const getPlaylistSongsAsync = createAsyncThunk(
    "playlist/get",
    async ({start, limit, sortBy, append}, thunkAPI) => {
        try {
            if(!append)
                thunkAPI.dispatch(getPlaylistSongsStart());
            const response = await apiGET("playlist", {start, limit, sortBy});
            if (response.success){
                if(!append)
                    thunkAPI.dispatch(getPlaylistSongsSuccess(response));
                else
                    thunkAPI.dispatch(appendResult(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
        
    }
)

export const searchPlaylistSongsAsync = createAsyncThunk(
    "playlist/search",
    async ({query}, thunkAPI) => {
        try {
            thunkAPI.dispatch(searchPlaylistSongsStart());
            const response = await apiGET("playlist-search-song", {query});
            
            if (response.success)
                thunkAPI.dispatch(searchPlaylistSongsSuccess(response));
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)

const playlistSlice = createSlice({
    name: "playlist",
    initialState: {
        playlistSongs: null,
        totalPages: 0,
        loading: false,
        error: null
    },
    reducers: {
        addSongToPlaylistSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        removeSongFromPlaylistSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        getPlaylistSongsSuccess: (state, action) => {
            state.playlistSongs = action.payload.songs;
            state.totalPages = action.payload.totalPages;
            state.loading = false;
            state.error = null;
        },
        appendResult: (state, action) => {
          if(!state.playlistSongs){
              state.playlistSongs = action.payload.songs;
          }  
          else{
              let count = 0;
              for(let i = 0; i < action.payload.songs.length; i++){
                  const song = action.payload.songs[i]
                  if(state.playlistSongs.find(s => s._id === song._id)){
                        count++;
                  }
              }
              if(count < action.payload.songs.length)
                state.playlistSongs = [...state.playlistSongs, ...action.payload.songs];
          }
          state.totalPages = action.payload.totalPages;
          state.loading = false;
          state.error = null;
        },
        searchPlaylistSongsSuccess: (state, action) => {
            state.playlistSongs = action.payload.songs;
            state.totalPages = action.payload.totalPages;
            state.loading = false;
            state.error = null;
        },
        getPlaylistSongsStart: (state) => {
            state.playlistSongs = null;
            state.loading = true;
            state.error = null;
        },
        searchPlaylistSongsStart: (state) => {
            state.playlistSongs = null;
            state.loading = true;
            state.error = null;
        },

    },
})

export const { addSongToPlaylistSuccess, removeSongFromPlaylistSuccess, getPlaylistSongsSuccess, searchPlaylistSongsSuccess
    , getPlaylistSongsStart, searchPlaylistSongsStart, appendResult
} = playlistSlice.actions
export const selectPlaylistSongs = state => state.playlist.playlistSongs
export const selectTotalPages = state => state.playlist.totalPages
export default playlistSlice.reducer