import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPOST, apiGET } from "../../../apis/service";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY//REPLACE THIS WITH YOUR STRIPE KEY
export const createPaymentAsync = createAsyncThunk(
    "payment/create",
    async ({ videoId }, thunkAPI) => {
        try {
            const stripe = await loadStripe(STRIPE_KEY);
            const response = await apiPOST("video-payment", { videoId });
            if (response.success) {
                const session = response.session;
                const result = await stripe.redirectToCheckout({ sessionId: session.id });
                if (result.error) {
                    toast.error("Error creating payment");
                }
            }
        } catch (error) {
            toast.error(error.message);
        }
    }
)
const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        
    },
    reducers: {},
});

export const {} = paymentSlice.actions
export default paymentSlice.reducer