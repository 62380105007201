import styles from "../../../styles/component-styles/general/grid.module.scss";
import { Card } from "./card";
import no_data from "../../../assets/images/no_data.svg";
import loader from "../../../assets/gifs/loader.webp";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { constants } from "../../../constants";
import { useState } from "react";
import { WatchVideo } from "../../admin/modals/watchVideo";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectedVideoAction } from "./selected-video-action";

export function Grid({ videos, setCurrentPage, currentPage, totalPages, isAdmin, deleteVideos }) {
  const language = useSelector(selectLanguage);

  const [searchParams, setSearchParams] = useSearchParams();
  const onClick = (id) => {
    setSearchParams({ videoId: id });
  };
  const onClose = () => {
    setSearchParams({});
  };
  const [ref, inView] = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) {
      if (currentPage <= totalPages) setCurrentPage(currentPage + 1);
    }
  }, [inView]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const onVideoSelect = (_id) => {
    setSelectedVideos([...selectedVideos, _id]);
  };
  const onVideoDeselect = (_id) => {
    setSelectedVideos(selectedVideos.filter((id) => id !== _id));
  };
  const onCancel = () => {
    setSelectedVideos([]);
  };
  const onDelete = () => {
    deleteVideos(Array.from(selectedVideos));
    setSelectedVideos([]);
    // setCurrentPage(1)
  };
  return (
    <div className={styles.grid}>
      <WatchVideo
        isOpen={searchParams.get("videoId") != null}
        videoId={searchParams.get("videoId")}
        onClose={onClose}
        isAdmin={isAdmin}
      />
      {videos &&
        videos.length > 0 &&
        videos.map((video, index) => (
          <span key={index} ref={index === videos.length - 1 ? ref : null}>
            <Card
              title={video.title}
              description={video.description}
              videoUrl={video.videoUrl}
              createdAt={video.createdAt}
              view={video.view}
              onClick={onClick}
              isAnySelected={selectedVideos.length > 0}
              isAdmin={isAdmin}
              onSelect={onVideoSelect}
              onSelectRemove={onVideoDeselect}
              key={video._id}
              _id={video._id}
              isSelected={selectedVideos.includes(video._id)}
            />
          </span>
        ))}
      {!videos && (
        <div className={styles.noData}>
          <img src={loader} />
          <p>{constants[language].loading}</p>
        </div>
      )}
      {videos && videos.length === 0 && (
        <div className={styles.noData}>
          <img src={no_data} />
          <p>{constants[language].no_data}</p>
        </div>
      )}
      {selectedVideos.length > 0 && (
        <div className={styles.overlay}>
          <SelectedVideoAction number={selectedVideos.length} onDelete={onDelete} onCancel={onCancel} />
        </div>
      )}
    </div>
  );
}
