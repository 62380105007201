import styles from "../../../styles/component-styles/customer/ads-slider.module.scss";
import { ads } from "./constant";
import { useEffect, useState } from "react";
import cross from "../../../assets/icons/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectShowAds, updateShowAds } from "../../../redux/slice/ui/ui-slice";
import { getTopAdsAsync, selectTopAds } from "../../../redux/slice/ad/ad-slice";
import { constants } from "../../../constants";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import noImage from "../../../assets/images/no-image.png"
export function AdsSlider(){
    const [current, setCurrent] = useState(0);
    const ads = useSelector(selectTopAds)
    useEffect(() => {
        if(ads && ads.length === 1){
            setCurrent(0)
            return
        }
        const interval = setInterval(() => {
            setCurrent(current === ads?.length - 1 ? 0 : current + 1);
        }, 5000);
        return () => clearInterval(interval);
    }, [current]);
    useEffect(() => {
        if(!ads || ads.length === 0) {
            setCurrent(0)
            dispatch(getTopAdsAsync({}))
        }
    }, [])
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(updateShowAds({showAds: false}))
    }
    const showAds = useSelector(selectShowAds)
    const language = useSelector(selectLanguage)
    return <div className={styles.container}>
        {ads && ads.length>0 && showAds && <>
        <img src={ads[current]?.image || noImage} className={styles.img}
        onError={(e)=>{
            e.target.src = noImage
        }}
        />
        {/* <div className={styles.overlay}>
            <div className={styles.content}>
                <div className={styles.text}>
                <div className={styles.header}>
                    <h2>{ads[current]?.title}</h2>
                    <hr/>
                    <p>{new Date(ads[current]?.createdAt).toDateString()}</p>
                </div>
                <p className={styles.desc}>{ads[current]?.description}</p>
                </div>
            </div>

            <div className={styles.dots}>
                {ads && ads.map((item, index) => (
                    <span key={index} className={index === current ? styles.active : ''} onClick={() => setCurrent(index)}></span>
                ))}
            </div>
        </div> */}
        </>}
        </div>
}