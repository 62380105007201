import styles from "../../../styles/component-styles/admin/layout.module.scss";
import { MobileSidebar, Sidebar } from "./sidebar";
import { Navbar, MobileNavbar } from "./navbar";
import { useEffect, useState } from 'react';
export function Layout({children}){

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const openSidebar = ()=>{
        setSidebarOpen(true)
    }
    return <div className={styles.container}>
        <Sidebar/>
        <MobileSidebar isOpen={sidebarOpen}
        setOpen={setSidebarOpen}
        />
        <div className={styles.content}>
        <Navbar/>
        <MobileNavbar openSidebar={openSidebar}/>
        <div className={styles.children}>
        {children}
        </div>
        </div>
    </div>
}