import { useRef } from "react"
import dd from "../../../assets/icons/drop-down.svg";
import check_ic from '../../../assets/icons/check-white.svg';
import styles from '../../../styles/component-styles/general/input.module.scss';
import { useEffect } from 'react';
const Selector = ({ onChange, value, options }) => {
    const selectorRef = useRef(null);
    const dropdownRef = useRef(null);
    const handleClick = (e) => {
        if (dropdownRef.current) {
            if (dropdownRef.current.style.display === 'flex') {
                dropdownRef.current.style.display = 'none';
            }
            else{
                dropdownRef.current.style.display = 'flex';
            }
        }
        else {
            dropdownRef.current.style.display = 'none';
        }
    }
    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (selectorRef.current) {
                if (!selectorRef.current.contains(e.target)) {
                    dropdownRef.current.style.display = 'none';
                }
            }
        });
    }, []);
    return (
        <div className={styles.selector} ref={selectorRef}>
            <span className={styles.selected} onClick={handleClick} id="1">
                <div className={styles.name} id="1">{options.find(l => l.value === value).label}</div>
            </span>
            <img src={dd} onClick={handleClick} id="1" />
            <div className={styles.dropdown} ref={dropdownRef}>
                {options.map((option, index) => (
                    <div key={index} onClick={() => {
                        onChange(option.value)
                        handleClick()
                    }}

                        className={value === option.value ? styles.active : ''}>
                        <span>
                            {/* <img src={language1.flag} /> */}
                            {option.label}
                        </span>
                        <img src={check_ic} />
                    </div>
                ))}
            </div>
        </div>

    )
}
export default Selector