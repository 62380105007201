import styles from "../../styles/page-styles/signup/signup.module.scss"
import laptop from "../../assets/images/laptop.svg"
import { Link } from "react-router-dom";
import google from "../../assets/icons/google.svg"
import { Divider } from "../../components/signup/divider";
import { TextField } from "../../components/general/input/textfield";
import { SecondaryButton } from "../../components/general/input/secondary-button";
import { PrimaryButton } from "../../components/general/input/primary-button";
import { Footer } from "../../components/signup/footer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { validateRegisterUserData } from "../../utils/validate-register-user-data";
import { registerUserAsync } from "../../redux/slice/auth/auth-slice";
import { toast } from "react-toastify";
import { LoaderModal } from "../../components/login/loader-modal";
export function Signup() {
    const [userDetails, setUserDetails] = useState({});
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const handleSignup = () => {
        const { errors, noError } = validateRegisterUserData(userDetails);
        setError(errors);
        if (noError) {
            dispatch(registerUserAsync(userDetails));
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value });
    }

    return <div className={styles.signup}>
        <div className={styles.container}>
            <LoaderModal />
            <div className={styles.left}>
                <div className={styles.form}>
                    <div className={styles.top}>
                        <h1 className={styles.title}>Get's started</h1>
                        <p className={styles.subtitle}>Already have an account? <Link to='/'>Sign in</Link></p>
                    </div>
                    {/*<div className={styles.googleSignIn}>
                        <SecondaryButton className={styles.googleButton}>
                            <img src={google} alt="google" className={styles.googleIcon} />
                            Sign in with Google</SecondaryButton>
                    </div>*/}
                    {/* <Divider text={"or"} /> */}
                    <div className={styles.formFields}>
                        <TextField label="Full Name" placeholder="Enter your full name" type="text"
                            name={"name"}
                            onChange={handleInputChange}
                            error={error?.name}
                        />
                        <TextField label="Email" placeholder="Enter your email" type="email"
                            name={"email"}
                            onChange={handleInputChange}
                            error={error?.email}
                        />
                        <TextField label="Password" placeholder="Enter your password" type="password"
                            name={"password"}
                            onChange={handleInputChange}
                            error={error?.password}
                        />
                    </div>
                    <div className={styles.terms}>
                        <span>
                        <input type="checkbox" id="terms"
                            value={userDetails.terms}
                            onChange={() => setUserDetails({ ...userDetails, terms: !userDetails.terms })}
                        />
                        </span>
                        <label htmlFor="terms">I agree to the Terms of Service and Privacy Policy.</label>
                    </div>
                    {error?.terms && <p className={styles.error}>{error.terms}</p>}
                    <PrimaryButton
                        onClick={handleSignup}
                    >Sign up</PrimaryButton>
                </div>
            </div>
            <div className={styles.right}>
                <span className={styles.eclipse}>

                </span>
                <img src={laptop} alt='laptop' />
            </div>
        </div>
        <Footer />
    </div>

}