// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPOST, apiPOST_Tokenless } from "../../../apis/service";
import { toast } from "react-toastify";
import { updateIsLoaderOpen } from "../ui/ui-slice";

export const contactUsAsync = createAsyncThunk(
  "auth/contactUs",
  async ({ name, email, message, address }, thunkAPI) => {
    try {
      const response = await apiPOST("contact-us", {
        name,
        email,
        address,
        message,
      });
      if (response.success) {
        toast.success("Thanks for contacting us!");
      }
      return response;
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const loginUserAsync = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateIsLoaderOpen({ isLoaderOpen: true }));
      thunkAPI.dispatch(loginUserStart());
      const response = await apiPOST_Tokenless("login", {
        email,
        password,
      });
      if (response.success) {
        thunkAPI.dispatch(loginUserSuccess(response));
        toast.success("Logged in successfully!");
      }
      else {
        thunkAPI.dispatch(loginUserFail(response.message));
        toast.error(response.message);
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(loginUserFail(error.message));
      toast.error("Login Failed! Try again with correct credentials");
    }finally{
      thunkAPI.dispatch(updateIsLoaderOpen({ isLoaderOpen: false }));
    }
  }
);

export const registerUserAsync = createAsyncThunk(
  "auth/register",
  async ({ name, email, password }, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateIsLoaderOpen({ isLoaderOpen: true }));
      thunkAPI.dispatch(registerUserStart());
      const response = await apiPOST_Tokenless("register", {
        name,
        email,
        password,
      });
      if (response.success) {
        thunkAPI.dispatch(registerUserSuccess(response));
        toast.success("Registered successfully!");
      }
      else {
        thunkAPI.dispatch(registerUserFail(response.message));
        toast.error(response.message);
      }
      return response;
    } catch (error) {
      // alert(error.message);
      toast.error("Registeration Failed! Try again");
      thunkAPI.dispatch(registerUserFail(error.message));
    }finally{
      thunkAPI.dispatch(updateIsLoaderOpen({ isLoaderOpen: false }));
    }
  }
);
export const logoutUser = createAsyncThunk("auth/logout", async ({ }, thunkAPI) => {
  try {
    localStorage.clear();
    thunkAPI.dispatch(logoutUserSuccess());
    return true;
  } catch (error) {
    return error;
  }
});
export const forgotPasswordAsync = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await apiPOST_Tokenless("forgot-password", {
        email, password
      });
      if (response.success) {
        toast.success("Password updated Successfully!");
        thunkAPI.dispatch(forgetPasswordSuccess(response));
      }
      else {
        toast.error(response.message);
      }
      return response;
    } catch (error) {
      // alert(error.message);
      toast.error("Password update failed! Try again");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("token") || null,
    loading: false,
    error: null,

    isRegisterLoading: null,
    isRegisterError: false,

  },
  reducers: {
    loginUserStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginUserSuccess: (state, action) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loading = false;
    },
    loginUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    registerUserStart: (state) => {
      state.isRegisterLoading = true;
      state.isRegisterError = null;
    },
    registerUserSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);

    },
    registerUserFail: (state, action) => {
      state.isRegisterLoading = false;
      state.isRegisterError = action.payload;
    },
    logoutUserSuccess: (state) => {
      state.user = null;
      state.token = null;
    },
    forgetPasswordSuccess: (state, action) => {
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  },
});

export const {
  loginUserStart,
  loginUserSuccess,
  loginUserFail,

  registerUserFail,
  registerUserStart,
  registerUserSuccess,
  logoutUserSuccess,
  forgetPasswordSuccess
} = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;


export default authSlice.reducer;
