import styles from "../../../styles/component-styles/admin/layout.module.scss";
import profile from "../../../assets/icons/user.png";
import { selectUser } from "../../../redux/slice/auth/auth-slice";
import { useSelector } from "react-redux";
export function Profile() {
    const user = useSelector(selectUser)
    return (
        <div className={styles.profile}>
            <span className={styles.name}>{user?.name}</span>
            <img src={profile} alt="profile" />
        </div>
    );
}