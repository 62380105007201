import styles from "../../../styles/component-styles/general/grid.module.scss"
import noImage from "../../../assets/images/no-image.png"
import { formatAgo } from "../../../utils/formatAgo"
import { formatViews } from "../../../utils/formatViews"
import { useEffect, useRef } from "react"
export function Card({ title, createdAt, videoUrl, _id, onClick, isAdmin = false, isAnySelected, onSelect, onSelectRemove, isSelected}) {
    const checkboxRef = useRef(null)
    useEffect(() => {
        if(checkboxRef.current && (checkboxRef.current.checked === false && !isAnySelected)){
            checkboxRef.current.style.display = "none"
        }
        else if(checkboxRef.current){
            checkboxRef.current.style.display = "block"
        }
    }, [isAnySelected])
    return (
        <div className={styles.card} onClick={(event) =>onClick(_id)}
        onMouseEnter={(event) => {
            if(checkboxRef.current){
                checkboxRef.current.style.display = "block"
            }
        }}
        onMouseLeave={(event) => {
            if(checkboxRef.current && (checkboxRef.current.checked === false && !isAnySelected)){
                checkboxRef.current.style.display = "none"
            }
        }}
        >

            {isAdmin && <input type="checkbox" ref={checkboxRef} className={styles.checkbox}
            style={{display:"none"}}
            checked={isSelected}
            onClick={(event) => {
                event.stopPropagation()
                const isChecked = event.target.checked
                if(isChecked){
                    onSelect(_id)
                }
                else{
                    onSelectRemove(_id)
                }
            }}
            onChange={(event) => {
                event.stopPropagation()
            }}
            />}
            <video src={videoUrl} className={styles.thumbnail}
                onError={(e) => {
                    e.target.src = noImage
                }}
                controls={false}
            />

            <h5 className={styles.title}>{title}</h5>
            {/* <p className={styles.description}>{formatViews(view)} • {formatAgo(createdAt)}</p> */}
            <p className={styles.description}>{formatAgo(createdAt)}</p>
        </div>
    )
}