import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { apiDELETE, apiGET, apiPOST_File } from "../../../apis/service";
import { toast } from "react-toastify";
export const createAdAsync = createAsyncThunk(
    "ad/create",
    async ({ formData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(createAdStart());
            const response = await apiPOST_File("ad", formData);
            if (response.success){
                toast.success("Ad created successfully!");
                thunkAPI.dispatch(createAdSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const removeAd = createAsyncThunk(
    "ad/remove",
    async ({ adId }, thunkAPI) => {
        try {
            const response = await apiDELETE("ad", { adId });
            if (response.success){
                toast.success("Ad removed successfully!");
                thunkAPI.dispatch(removeAdSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const getAdsAsync = createAsyncThunk(
    "ad/get",
    async ({}, thunkAPI) => {
        try {
            thunkAPI.dispatch(getAdsStart());
            const response = await apiGET("ads");
            if (response.success){
                thunkAPI.dispatch(getAdsSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)

export const getAdAsync = createAsyncThunk(
    "ad/getbyId",
    async ({ adId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getAdStart());
            const response = await apiGET(`ad`,{adId});
            if (response.success){
                thunkAPI.dispatch(getAdSuccess(response));
            }
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
export const getTopAdsAsync = createAsyncThunk(
    "ad/top",
    async ({ no = 6 }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getTopAdsStart());
            const response = await apiGET("top-ads", { no });
            if (response.success)
                thunkAPI.dispatch(getTopAdsSuccess(response));
            else
                toast.error(response.message);
            return response
        } catch (error) {
            toast.error(error.message);
        }
    }
)
const adSlice = createSlice({
    name: "ad",
    initialState: {
        loading: false,
        error: null,
        ad: null,
        ads: null,
        topAds: null,
    },
    reducers: {
        createAdStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        createAdSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.ad = action.payload.ad;
        },
        removeAdSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
        },
        getAdsStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getAdsSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.ads = action.payload.ads;
        },
        getAdStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getAdSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.ad = action.payload.ad;
        },
        getTopAdsStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        getTopAdsSuccess: (state, action) => {
            state.loading = false;
            state.error = null;
            state.topAds = action.payload.ads;
        },
    }
})

export const { createAdStart, createAdSuccess, removeAdSuccess, getAdsStart, getAdsSuccess, getAdStart, getAdSuccess, getTopAdsStart, getTopAdsSuccess } = adSlice.actions;
export const selectAd = state => state.ad.ad;
export const selectAds = state => state.ad.ads;
export const selectTopAds = state => state.ad.topAds;
export default adSlice.reducer