import { Overlay } from "./overlay";
import style from "../../../styles/component-styles/admin/modals.module.scss";
import close_ic from "../../../assets/icons/close.svg";
import { DownloadInfoTable } from "../../general/table/download-info-table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../../redux/slice/language/laguage.slice";
import { getVideoReports, selectVideoReport, selectVideoReportTotalPages } from "../../../redux/slice/reports/reports-slice";
import { constants } from "../../../constants";
export function DownloadsDetail({ isOpen, onClose, videoId }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState("createdAtDesc");
    const dispatch = useDispatch();
    const LIMIT = 10;
    const language = useSelector(selectLanguage)
    const videoInfo = useSelector(selectVideoReport)
    const totalPages = useSelector(selectVideoReportTotalPages)
    const linkRef = useRef(null)
    useEffect(() => {
        setCurrentPage(1);
        dispatch(getVideoReports({ start: (0) * LIMIT, limit: LIMIT, sortBy, videoId, append: false, action:"download" }));
    }, [])
    useEffect(() => {
        dispatch(getVideoReports({ start: (currentPage - 1) * LIMIT, limit: LIMIT, sortBy, videoId, append: false, action:"download" }));
    }, [videoId])
    useEffect(() => {
        dispatch(getVideoReports({ start: (currentPage - 1) * LIMIT, limit: LIMIT, sortBy, videoId, append: true, action:"download" }));
    }, [currentPage])
    useEffect(() => {
        dispatch(getVideoReports({ start: (0) * LIMIT, limit: LIMIT, sortBy, videoId, append: true, action:"download" }));
    }, [sortBy])
    const onSort = (field) => {
        setSortBy(field)
    }
    const onExport = () => {
        if(linkRef.current)
            linkRef.current.click()
    }
    return (
        <>
            {isOpen && <Overlay>
                <div className={style.downloadDetailModal}>
                    <div className={style.close}>
                        <img src={close_ic} onClick={onClose} />
                    </div>
                    <div className={style.content}>
                        <a href={`${process.env.REACT_APP_BACKEND_URL}/video-reports-csv?videoId=${videoId}&action=download&access_token=Bearer ${localStorage.getItem("token")}`} ref={linkRef}></a>
                        <button className={style.exportBtn}
                        onClick={onExport}
                        >{constants[language].export_to_csv}</button>
                        <DownloadInfoTable  
                        onSort={onSort} totalPages={totalPages}
                        sortBy={sortBy} setCurrentPage={setCurrentPage}
                        currentPage={currentPage} videoInfo={videoInfo}
                        />
                    </div>
                </div>
            </Overlay>}
        </>
    );
}