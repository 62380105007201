import styles from "../../../styles/component-styles/general/input.module.scss";
import { useState } from "react";
import eye_ic from "../../../assets/icons/eye.svg";
import eye_hidden_ic from "../../../assets/icons/eye_hidden.svg";
export function TextField({ label, placeholder, type, value, onChange, error, name, style, disabled = false }) {
    const [show, setShow] = useState(false);
    return (
        <div className={styles.textField}>
            <label className={styles.label}>{label}</label>
            <div className={styles.input} style={style}>
                {type !== "password" ? (
                type === "date"?
                <input type={"text"} placeholder={placeholder} value={value} onChange={onChange}
                onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.showPicker();
                }}
                
                onBlur={(e) => {
                    e.currentTarget.type = "text";
                    
                }}
                />
                :
                <input type={type} placeholder={placeholder} value={value} onChange={onChange}
                    name={name}
                    disabled={disabled}
                />) :
                    <input type={show ? "text" : "password"} placeholder={placeholder} value={value} onChange={onChange}
                        name={name}
                        className={styles.passwordField}
                        disabled={disabled}
                    />}
                {type === "password" && <img src={show ? eye_hidden_ic : eye_ic}
                    onClick={() => {
                        if (!disabled) setShow(!show);
                    }}
                />}
            </div>

            {error && <p className={styles.error}>{error}</p>}
        </div>
    )
}
