import { upload } from "@testing-library/user-event/dist/upload";

export function convertDateFormat(date) {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth();
  const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
  return `${day} / ${month} / ${year}`;
}

export const calculateVideoUploadProgress = (uploadProgress) => {
  if (!uploadProgress || uploadProgress.length < 1) return 0;
  return Object.values(uploadProgress).reduce((acc, curr) => acc + curr, 0) / Object.keys(uploadProgress).length;
};
