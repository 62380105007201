import React, { useEffect, useRef, useState } from 'react'
import styles from '../../../styles/page-styles/admin/reports.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguage } from '../../../redux/slice/language/laguage.slice'
import { updateTab } from '../../../redux/slice/ui/ui-slice'
import { VideosInfoTable } from '../../../components/general/table/videos-info-table'
import { constants } from '../../../constants'
import { getVideosReports, getVideosReportsCSV, selectReports, selectTotalPages } from '../../../redux/slice/reports/reports-slice'
import { DownloadsDetail } from '../../../components/admin/modals/downloads-detail'
import { ViewsDetail } from '../../../components/admin/modals/views-detail'
export function Reports() {
    const language = useSelector(selectLanguage)
    const dispatch = useDispatch()
    
    const [id, setId] = useState(null)
    const [openDownloadInfo, setOpenDownloadInfo] = useState(false)
    const [openViewInfo, setOpenViewInfo] = useState(false)
    const [sortBy, setSortBy] = useState('uploadedOnDesc')
    const [currentPage, setCurrentPage] = useState(1)
    const LIMIT = 10
    const totalPages = useSelector(selectTotalPages)
    const videoReports = useSelector(selectReports)
    const linkRef = useRef(null)
    useEffect(() => {
        dispatch(updateTab({ tab: 'Reports' }))
        if(videoReports){
            dispatch(getVideosReports({ start: (currentPage - 1) * LIMIT, limit: LIMIT, sortBy, append:false }))
        }
    }, [])
    useEffect(() => {
        dispatch(getVideosReports({ start: (0) * LIMIT, limit: LIMIT, sortBy, append:true }))
    }, [currentPage])
    useEffect(() => {
        setCurrentPage(1)
        dispatch(getVideosReports({ start: (0) * LIMIT, limit: LIMIT, sortBy, append:false }))
    }, [sortBy])
    const onClickDownloadInfo = (_id) => {
        setId(_id)
        setOpenDownloadInfo(true)
    }
    const onClickViewInfo = (_id) => {
        setId(_id)
        setOpenViewInfo(true)
    }
    const onSort = (field) => {
        setSortBy(field)
    }
    const onExport = () => {
        linkRef.current.click()
    }

    return <div className={styles.container}>
        <div className={styles.actionBtn}>
            <a href={`${process.env.REACT_APP_BACKEND_URL}/videos-reports-csv?access_token=Bearer ${localStorage.getItem("token")}`} download={"Video Stats"}
            ref={linkRef}
            ></a>
            <button className={styles.exportBtn}
            onClick={onExport}
            >{constants[language].export_to_csv}</button>
        </div>
        {openDownloadInfo && <DownloadsDetail isOpen={openDownloadInfo} onClose={() => setOpenDownloadInfo(false)} videoId={id} />}
        {openViewInfo && <ViewsDetail isOpen={openViewInfo} onClose={() => setOpenViewInfo(false)} videoId={id} />}
        <div className={styles.table}>
        <VideosInfoTable onClickDownloadInfo={onClickDownloadInfo} onClickViewInfo={onClickViewInfo}
        videosInfo={videoReports}
        onSort={onSort} sortBy={sortBy} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
        />
        </div>
        </div>
}