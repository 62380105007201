import styles from '../../../styles/component-styles/general/table.module.scss';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice/language/laguage.slice';
import { constants } from '../../../constants';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import no_data from "../../../assets/images/no_data.svg"
import loader from "../../../assets/gifs/loader.webp"
import { convertDateFormat } from '../../../utils/convert-date-format';
import { convertDatetimeFormat } from '../../../utils/convert-datetime-formet';
export function VideosInfoTable({ videosInfo, onSort = () => { }, sortBy, setCurrentPage, currentPage, totalPages, onClickDownloadInfo = () => { }, onClickViewInfo = () => { } }) {
    const language = useSelector(selectLanguage)
    const [ref, inView] = useInView({ triggerOnce: false })
    useEffect(() => {
        if (inView) {
            if (currentPage < totalPages)
                setCurrentPage(currentPage + 1)
        }
    }, [inView])
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th className={styles.head}>{constants[language].no}</th>
                    <th className={styles.head}>
                        <div className={styles.header}>
                            {constants[language].video_title}
                            <div className={styles.sorting}>
                                <button className={styles.sortingBtn + " " + (sortBy == 'titleAsc' ? styles.active : "")}
                                    onClick={() => onSort('titleAsc')}
                                >
                                    ▲
                                </button>
                                <button className={styles.sortingBtn + " " + (sortBy == 'titleDesc' ? styles.active : "")}
                                    onClick={() => onSort('titleDesc')}>
                                    ▼
                                </button>
                            </div>
                        </div>
                    </th>
                    <th className={styles.head}>
                        <div className={styles.header}>
                            {constants[language].downloads}
                            <div className={styles.sorting}>
                                <button className={styles.sortingBtn + " " + (sortBy == 'downloadAsc' ? styles.active : "")}
                                    onClick={() => onSort('downloadAsc')}
                                >
                                    ▲
                                </button>
                                <button className={styles.sortingBtn + " " + (sortBy == 'downloadDesc' ? styles.active : "")}
                                    onClick={() => onSort('downloadDesc')}>
                                    ▼
                                </button>
                            </div>
                        </div>
                    </th>
                    <th className={styles.head}>
                        <div className={styles.header}>
                            {constants[language].views}
                            <div className={styles.sorting}>
                                <button className={styles.sortingBtn + " " + (sortBy == 'viewAsc' ? styles.active : "")}
                                    onClick={() => onSort('viewAsc')}
                                >
                                    ▲
                                </button>
                                <button className={styles.sortingBtn + " " + (sortBy == 'viewDesc' ? styles.active : "")}
                                    onClick={() => onSort('viewDesc')}>
                                    ▼
                                </button>
                            </div>
                        </div>
                    </th>
                    <th className={styles.head}>
                        <div className={styles.header}>
                            {constants[language].uploaded_on}
                            <div className={styles.sorting}>
                                <button className={styles.sortingBtn + " " + (sortBy == 'uploadedOnAsc' ? styles.active : "")}
                                    onClick={() => onSort('uploadedOnAsc')}
                                >
                                    ▲
                                </button>
                                <button className={styles.sortingBtn + " " + (sortBy == 'uploadedOnDesc' ? styles.active : "")}
                                    onClick={() => onSort('uploadedOnDesc')}>
                                    ▼
                                </button>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style={{ height: "fit-content" }}>
                    <td colSpan={5}>
                        <div className={styles.line}></div>
                    </td>

                </tr>
                {videosInfo && videosInfo.map((video, index) => {
                    return (
                        <tr key={video._id}
                            ref={index === videosInfo.length - 1 ? ref : null}
                        >
                            <td><div className={styles.no}>{index + 1}</div></td>
                            <td><div className={styles.title}>
                                <span>{video.title}</span>
                            </div></td>
                            <td><div className={styles.downloads}
                                onClick={() => onClickDownloadInfo(video._id)}
                            >{video.download}</div></td>
                            <td><div className={styles.views}
                                onClick={() => onClickViewInfo(video._id)}
                            >{video.view}</div></td>
                            <td><div className={styles.uploadedOn}>{convertDatetimeFormat(video.uploadedOn)}</div></td>
                        </tr>
                    )
                })}
                {videosInfo && videosInfo?.length == 0 && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={no_data} />
                    <p>{constants[language].no_data}</p>
                </div>
            </td></tr>}
            {!videosInfo && <tr><td colSpan={5}>
                <div className={styles.noData}>
                    <img src={loader} />
                    <p>{constants[language].loading}</p>
                </div>
            </td></tr>}
            </tbody>
        </table>
    )
}