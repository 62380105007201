import { useSelector } from "react-redux";
import { selectUser } from "../redux/slice/auth/auth-slice";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { NotFound } from "../pages/not-found";

const ProtectedRoute = () => {
  //logic to protect routes
  const user = useSelector(selectUser)
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  if(!user){
    return <Navigate to='/'/>
  }
  
  if((currentPath === 'admin' && user?.role === 'admin') || 
  (currentPath === 'customer' && user?.role === 'customer')){
    return <Outlet/>
  }
  
  return <NotFound/>
  
};
export default ProtectedRoute;
